import {Button, Grid, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import classNames from 'classnames'
import styles from "../../../assets/jss/material-dashboard-react/components/CustomPricingStyle";
import Letter from '../../../assets/img/letter.svg';

const useStyles = makeStyles(styles)

const HelpSection = props => {
  const classes = useStyles();
  const {removals} = props;
  return (
    <Grid container alignItems={'center'} className={classNames(classes.helpWrap, {[classes.helpWrapRemoveDivider]: removals})} style={props.removals ? { borderRadius: 30} : {}} id={'pricing-help-section'}>
      <Grid>
        <Typography className={classNames(classes.helpTopHead, {[classes.helpTopHeadRemovals]: removals})}>Have some questions first?</Typography>
      </Grid>
      <Grid className={classes.buttonWrap}>
        <Button
          size="small"
          variant="contained"
          component={Link}
          to="/contact-us"
          className={classes.contactUsButton}
          id={'contactUsButton'}
        >
          <img src={Letter} style={{marginRight: 5, width: 22.5, height: 22.5}} />Contact us
        </Button>
      </Grid>
    </Grid>
  )
}

export default HelpSection
