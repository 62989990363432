import React, {useContext, useEffect, useState, useMemo} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  Typography,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Popover,
  Icon,
  CircularProgress,
  Snackbar,
  Switch, TextField,
} from "@material-ui/core"
import HelpRounded from "@material-ui/icons/HelpRounded"
// Core components
import { useGlobalState } from "hooks/useGlobalState"
import Alert from '@material-ui/lab/Alert';
import intersection from 'lodash.intersection';
// Other components
import ColourPickerField from "components/ColourPickerField/ColourPickerField";
import {handleError, saveIntegration } from '../../../helpers/SaveIntegration';
import {getSignedUploadUrlForUser, uploadIntegrationLogo, getSignedUploadUrlUnitImage, uploadIntegrationUnitImage} from '../../../helpers/getLogoUrl'
import {deleteCustomUnitImage, deleteIntegrationLogo} from '../../../helpers/deleteIntegrationLogo'
import {useDropzone} from 'react-dropzone'
import UnpaidModal from '../../FreeVersion/Modal';
// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import classNames from "classnames";
import {mapPlansLimitations, designFeatures} from "../../../variables/InitState"; import PayButton from "../../FreeVersion/Button";

const useStyles = makeStyles(styles);

const DesignStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const [brandingUrlPop, setBrandingUrlPop] = useState(null);
  const [logoPop, setLogoPop] = useState(null);
  const [customUnitPop, setCustomUnitPop] = useState(null);
  const [loading, setLoading] = useState(false)
  const [uploadingError, setUploadingError] = useState(false)
  const [uploadingErrorUserUnit, setUploadingErrorUserUnit] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true);
  const [error, setError] = useState('');
  const { theRef, setUpdating, updating } = props;
  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);
  if (state?.signedInUser?.data?.user?.hideCopyright) {
    restrictions.push('integrationHideCopyright');
  }

  const showShoppingCart = useMemo(() => {
    const allLimits = mapPlansLimitations?.[state.currentPlan] || [];
    return intersection(allLimits, designFeatures).length !== designFeatures.length
  }, [state.currentPlan]);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: acceptedFiles => {
      handleLogoChange(acceptedFiles)
    },
    accept: 'image/jpeg, image/png',
    maxFiles:1
  })

  const {getRootProps: getRootPropsU, getInputProps: getInputPropsU} = useDropzone({
    onDrop: acceptedFiles => {
      handleUnitImageChange(acceptedFiles)
    },
    accept: 'image/jpeg, image/png',
    maxFiles:1
  });

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 4 } }) // Set stepper step number 5
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
    setFirstLoad(false);
  }, []);
  /* eslint-enable */

  const handleUploadingError = (e) => {
    setError('Failed uploading')
    setLoading(false)
    setUpdating(false)
    setUploadingError(true)
    console.log('Uploading error:', e)
    handleError(e);
  }

  const handleUploadingErrorUserUnit = (e) => {
    setError('Failed uploading')
    setLoading(false)
    setUpdating(false)
    setUploadingErrorUserUnit(true)
    console.log('Uploading error:', e)
  }

  const handleLogoChange = async (files) => {
    const file = files && files[0] ? files[0] : null
    if (!file)
      return

    setLoading(true)
    setUpdating(true)
    let data
    try {
      const response = await getSignedUploadUrlForUser({id: state.integrationData.id, type: file.type.replace('image/', '')}, state)
      data = (response && response.data) && response.data.res;
    } catch (e) {
      handleUploadingError(e)
      return
    }
    if (data?.url) {
      try {
        await uploadIntegrationLogo(data.url, file)
        handleDesignUpdate('logo', data.uri)
        setLoading(false)
        setUpdating(false)
      } catch (e) {
        setTimeout(() => setUploadingError(true), 3000);
        handleUploadingError(e)
      }
    }
  }

  const handleUnitImageChange = async (files) => {
    const file = files && files[0] ? files[0] : null
    if (!file)
      return

    setLoading(true)
    setUpdating(true)
    let data
    try {
      const response = await getSignedUploadUrlUnitImage({id: state.integrationData.id, type: file.type.replace('image/', '')}, state)
      data = (response && response.data) && response.data.res;
    } catch (e) {
      handleUploadingErrorUserUnit(e)
      return
    }
    if (data?.url) {
      try {
        await uploadIntegrationUnitImage(data.url, file)
        handleDesignUpdate('unitImage', data.uri)
        setLoading(false)
        setUpdating(false)
      } catch (e) {
        handleUploadingErrorUserUnit(e)
        setTimeout(() => setUploadingErrorUserUnit(true), 3000)
      }
    }
  }

  useEffect(()=>{
    if (firstLoad) return setFirstLoad(false);
    const timeout = setTimeout(async () => {
      setUpdating(true);
      try {
        await saveIntegration({ ...state, integrationData: { ...state.integrationData } }, false, dispatchState);
      } catch (e) {
        console.log('Saving error:', e)
      }
      setUpdating(false);
    }, 600);
    return () => clearTimeout(timeout);
  }, [
    state.integrationData.integrationPrimaryColour,
    state.integrationData.integrationActiveFont,
    state.integrationData.integrationUnitColor,
    state.integrationData.integrationSecondaryColour,
    state.integrationData.integrationActiveBgStyle,
    state.integrationData.integrationGradientOne,
    state.integrationData.integrationGradientTwo,
    state.integrationData.integrationLogo,
    state.integrationData.integrationSecondaryText,
    state.integrationData.integrationPrimaryText,
    state.integrationData.integrationButtonColour,
    state.integrationData.integrationButtonTextColour,
    state.integrationData.integrationUnitImage,
    state.integrationData.integrationCustomUnitImageEnabled,
    state.integrationData.integrationHideCopyright,
    state.integrationData.integrationHideIsometricGrid,
    state.integrationData.integrationCategoryButtonBorderColor,
    state.integrationData.integrationItemTextColour,
  ]);

  const removeIntegrationLogo = async () => {
    if (!state?.integrationData?.integrationLogo)
      return
    setLoading(true)
    setUpdating(true)
    try {
      handleDesignUpdate('logo', null)
      if (!state?.integrationData?.integrationCopy)
        await deleteIntegrationLogo(state)
      setLoading(false)
      setUpdating(false)
    } catch (e) {
      setLoading(false)
      setUpdating(false)
      setError('Failed removing')
      console.log('Error:', e)
      handleError(e);
    }
  }

  const removeUnitImage = async () => {
    if (!state?.integrationData?.integrationUnitImage)
      return
    setLoading(true)
    setUpdating(true)
    try {
      handleDesignUpdate('unitImage', null)
      if (!state?.integrationData?.integrationUnitImage)
        await deleteCustomUnitImage(state)
      setLoading(false)
      setUpdating(false)
    } catch (e) {
      setLoading(false)
      setUpdating(false)
      setError('Failed removing')
      console.log('Error:', e)
    }
  }

  const handleDesignUpdate = async (type, value) => {
    switch (type) {
      case "font":
        dispatchState({ type: 'integration-data', payload: { integrationActiveFont: value } });
        break;
      case "primaryColour":
        dispatchState({ type: 'integration-data', payload: { integrationPrimaryColour: value } });
        break;
      case "unitColor":
        dispatchState({ type: 'integration-data', payload: { integrationUnitColor: value } });
        break;
      case "secondaryColour":
        dispatchState({ type: 'integration-data', payload: { integrationSecondaryColour: value } });
        break;
      case "bg":
        dispatchState({ type: 'integration-data', payload: { integrationActiveBgStyle: value } });
        break;
      case "g1":
        dispatchState({ type: 'integration-data', payload: { integrationGradientOne: value } });
        break;
      case "g2":
        dispatchState({ type: 'integration-data', payload: { integrationGradientTwo: value } });
        break;
      case "buttonColour":
        dispatchState({ type: 'integration-data', payload: { integrationButtonColour: value } });
        break;
      case "buttonTextColour":
        dispatchState({ type: 'integration-data', payload: { integrationButtonTextColour: value } });
        break;
      case "logo":
        dispatchState({ type: 'integration-data', payload: { integrationLogo: value, integrationCopy: null } });
        break;
      case "unitImage":
        dispatchState({ type: 'integration-data', payload: { integrationUnitImage: value, integrationCopy: null } });
        break;
      case "isCustomUnitEnabled":
        dispatchState({ type: 'integration-data', payload: { integrationCustomUnitImageEnabled: !state.integrationData.integrationCustomUnitImageEnabled } });
        break;
      case "categoryButtonBorderColor":
        dispatchState({ type: 'integration-data', payload: { integrationCategoryButtonBorderColor: value } });
        break;
      case "integrationItemTextColour":
        dispatchState({ type: 'integration-data', payload: { integrationItemTextColour: value } });
        break;
    }
  }

  return (
    <form
      noValidate
      autoComplete="off"

      className={
        classNames(
          classes.standardForm,
          {
            [classes.disabledDesignScreen]: showShoppingCart,
          }
        )
      }
      id="custom-form"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Design</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />

          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Branding <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem" }} onClick={(event) => { setBrandingUrlPop(event.currentTarget) }} /></Typography>
          <Popover
            id="brandingURL"
            open={Boolean(brandingUrlPop)}
            anchorEl={brandingUrlPop}
            onClose={() => { setBrandingUrlPop(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography variant="body2" className={classes.blueText}>Selecting my website font will mean the Calcumate tool is displayed using the fonts in your website's styles.</Typography>
          </Popover>
          <FormControl fullWidth className={classNames({[classes.disabled]: !restrictions.includes('integrationActiveFont')})}>
            <InputLabel htmlFor="p-integration-language">Select font</InputLabel>
            <Select
              id="p-integration-font"
              value={state.integrationData.integrationActiveFont}
              variant="outlined"
              label="Select font"
              onChange={(e) => { handleDesignUpdate("font", e.target.value) }}
              disabled={!restrictions.includes('integrationActiveFont')}
            >
              {state.integrationData.integrationFontFamily.map((label, key) => (
                <MenuItem key={key} value={label}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}><span>Intro</span></Typography>
            <Grid item xs={12} className={classNames({[classes.disabled]: !restrictions.includes('integrationPrimaryText')})}>
              <TextField
                id="p-integration-primary-text"
                label={"Primary text"}
                variant="outlined"
                placeholder={"GET STORING"}
                type="text"
                onChange={e => dispatchState({ type: 'integration-data', payload: { integrationPrimaryText: e.target.value.toUpperCase() } })}
                value={state.integrationData.integrationPrimaryText ? state.integrationData.integrationPrimaryText : ''}
                fullWidth
                disabled={!restrictions.includes('integrationPrimaryText')}
              />
            </Grid>
        </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <TextField
                id="p-integration-secondary-text"
                label={"Secondary text"}
                placeholder={"Start by adding items to your storage unit"}
                variant="outlined"
                type="text"
                onChange={e => dispatchState({ type: 'integration-data', payload: { integrationSecondaryText: e.target.value } })}
                value={state.integrationData.integrationSecondaryText ? state.integrationData.integrationSecondaryText : ''}
                fullWidth
                disabled={!restrictions.includes('integrationSecondaryText')}
              />
            </Grid>
          </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Logo
            <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }} onClick={(event) => { setLogoPop(event.currentTarget) }} />
          </Typography>
          <Popover
            id="brandingURL"
            open={Boolean(logoPop)}
            anchorEl={logoPop}
            onClose={() => { setLogoPop(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography variant="body2" className={classes.blueText}>
              Upload a png or jpeg image file. We recommend a 16:9 scaled image.
            </Typography>
          </Popover>
          {!state.integrationData.integrationCustomUnitImageEnabled && (
            <div className={
              classNames({[classes.disabled]: !restrictions.includes('integrationLogo') })}
                 style={{
                   display: 'flex',
                   borderRadius: 4,
                   borderColor: 'rgba(0, 0, 0, 0.12)',
                   borderWidth: 1,
                   borderStyle: 'solid',
                   padding: 17
                 }}
            >
              {loading ? (<CircularProgress />) : state?.integrationData?.integrationLogo ?
                (
                  <div
                    style={{position: 'relative', width: 120, minHeight: 48}}
                  >
                    <Icon style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      zIndex: 2,
                      padding: 10,
                      cursor: "pointer",
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      backgroundColor: 'rgba(255, 255, 255, 0.6)'
                    }}
                          onClick={removeIntegrationLogo}
                    >
                      delete
                    </Icon>
                    <img
                      src={`${state.signedInUser.data.user.bucketDomain}${state.integrationData.integrationLogo}?salt=${Math.random()}`}
                      alt={'Logo'}
                      className={classes.integrationLogo}
                    />
                  </div>
                ) :
                (
                  <div {...getRootProps()} style={{cursor: 'pointer'}}>
                    <input {...getInputProps()} />
                    {
                      uploadingError ? (
                        <div style={{
                          color: '#f44336'
                        }}>
                          <p>File was not uploaded. Please, try again...</p>
                        </div>
                      ) : (<div><p>Drag and drop image here, or <b><u>select file</u></b></p></div>)
                    }
                  </div>
                )}
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography variant="subtitle1" component="h2" className={classes.subHeadOne} style={{marginTop: 0}}>Upload Unit Image
              <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 3 }} onClick={(event) => { setCustomUnitPop(event.currentTarget) }} />
            </Typography>
            <Popover
              id="brandingURL"
              open={Boolean(customUnitPop)}
              anchorEl={customUnitPop}
              onClose={() => { setCustomUnitPop(null) }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Typography variant="body2" className={classes.blueText}>
                If you want to upload your own unit/truck image, it must be in either png or jpeg format and at least 300 x 300px.
              </Typography>
            </Popover>
            <Switch
              checked={!!state.integrationData.integrationCustomUnitImageEnabled}
              onChange={() => handleDesignUpdate("isCustomUnitEnabled")}
              color="primary"
              name="disable-presets"
              inputProps={{ 'aria-label': 'disable presets' }}
            />
          </div>
          {
            state.integrationData.integrationCustomUnitImageEnabled && (
              <div className={
                classNames({[classes.disabled]: !restrictions.includes('integrationUnitImage') })}
                   style={{
                     display: 'flex',
                     borderRadius: 4,
                     borderColor: 'rgba(0, 0, 0, 0.12)',
                     borderWidth: 1,
                     borderStyle: 'solid',
                     padding: 17
                   }}
              >
                {loading ? (<CircularProgress />) : state?.integrationData?.integrationUnitImage ?
                  (
                    <div
                      style={{position: 'relative', width: 120, minHeight: 48}}
                    >
                      <Icon style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        zIndex: 2,
                        padding: 10,
                        cursor: "pointer",
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        backgroundColor: 'rgba(255, 255, 255, 0.6)'
                      }}
                            onClick={removeUnitImage}
                      >
                        delete
                      </Icon>
                      <img
                        src={`${state.signedInUser.data.user.bucketDomain}${state.integrationData.integrationUnitImage}?salt=${Math.random()}`}
                        alt={'Unit image'}
                        className={classes.integrationLogo}
                      />
                    </div>
                  ) :
                  (
                    <div>
                      <div {...getRootPropsU()} style={{cursor: 'pointer'}}>
                        <input {...getInputPropsU()} />
                        {
                          uploadingErrorUserUnit ? (
                            <div style={{
                              color: '#f44336'
                            }}>
                              <p>File was not uploaded. Please, try again...</p>
                            </div>
                          ) : (<div><p>Drag and drop image here, or <b><u>select file</u></b></p></div>)
                        }
                      </div>
                    </div>
                  )}
              </div>
            )
          }
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Colour scheme</Typography>
          <Grid container spacing={3}>
            <>
              <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationPrimaryColour') })}>
                <ColourPickerField
                  value={state.integrationData.integrationPrimaryColour}
                  onChange={(color) => { handleDesignUpdate("primaryColour", color) }}
                  id="p-integration-primary-color"
                  label="Primary colour"
                  disabled={!restrictions.includes('integrationPrimaryColour')}
                />
              </Grid>
              <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationSecondaryColour') })}>
                <ColourPickerField
                  value={state.integrationData.integrationSecondaryColour}
                  onChange={(color) => { handleDesignUpdate("secondaryColour", color) }}
                  id="p-integration-secondary-color"
                  label="Secondary colour"
                  disabled={!restrictions.includes('integrationSecondaryColour')}
                />
              </Grid>
              <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationUnitColor') })}>
                <ColourPickerField
                  value={state.integrationData.integrationUnitColor}
                  onChange={(color) => { handleDesignUpdate("unitColor", color) }}
                  id="p-integration-unit-color"
                  label="Unit colour"
                  disabled={!restrictions.includes('integrationUnitColor')}
                />
              </Grid>
            </>
            <Grid item xs={12}>
              <Divider className={classes.customDividerOne} />
              <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Background colour</Typography>
              <FormControl fullWidth className={classNames({[classes.disabled]: !restrictions.includes('integrationActiveBgStyle') })}>
                <InputLabel htmlFor="p-integration-language">Background type</InputLabel>
                <Select
                  id="p-integration-language"
                  value={state.integrationData.integrationActiveBgStyle}
                  variant="outlined"
                  label="Select language"
                  onChange={(e) => { handleDesignUpdate("bg", e.target.value) }}
                >
                  <MenuItem key={0} value="solid">Solid</MenuItem>
                  <MenuItem key={1} value="gradient">Gradient</MenuItem>
                  <MenuItem key={1} value="transparent">Transparent</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid
              item xs={12}
              spacing={3}
            >
              <Grid container style={{justifyContent: 'space-between'}}>
                <Grid item>
                  <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}> Show Isometric Grid </Typography>
                </Grid>
                <Grid item>
                  {
                    !restrictions.includes('integrationHideIsometricGrid') ? <PayButton setUpdating={setUpdating} feature={'integrationHideIsometricGrid'} updating={updating} /> : (
                      <Switch
                        checked={!state.integrationData.integrationHideIsometricGrid}
                        onChange={e => dispatchState({ type: 'integration-data', payload: { integrationHideIsometricGrid: !state.integrationData.integrationHideIsometricGrid } })}
                        color="primary"
                        name="hide-isometric-grid"
                        inputProps={{ 'aria-label': 'Hide isometric Grid' }}
                        disabled={!restrictions.includes('integrationHideIsometricGrid')}
                      />
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
            {state.integrationData.integrationActiveBgStyle === "solid" &&
            <Grid item xs={12} className={classNames({[classes.disabled]: !restrictions.includes('integrationGradientOne') })}>
              <ColourPickerField
                value={state.integrationData.integrationGradientOne}
                onChange={(color) => { handleDesignUpdate("g1", color) }}
                id="p-integration-gradient-one"
                label="Background colour"
                disabled={!restrictions.includes('integrationGradientOne')}
              />
            </Grid>
            }
            {state.integrationData.integrationActiveBgStyle === "gradient" &&
            <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationGradientOne') })}>
              <ColourPickerField
                value={state.integrationData.integrationGradientOne}
                onChange={(color) => { handleDesignUpdate("g1", color) }}
                id="p-integration-gradient-one"
                label="Gradient top colour"
                disabled={!restrictions.includes('integrationGradientOne')}
              />
            </Grid>
            }
            {state.integrationData.integrationActiveBgStyle === "gradient" &&
            <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationGradientTwo') })}>
              <ColourPickerField
                value={state.integrationData.integrationGradientTwo}
                onChange={(color) => { handleDesignUpdate("g2", color) }}
                id="p-integration-gradient-twto"
                label="Gradient bottom colour"
                disabled={!restrictions.includes('integrationGradientTwo')}
              />
            </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.customDividerOne} />
          <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Buttons</Typography>
          <Grid container spacing={3}>
            <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationButtonColour') })}>
              <ColourPickerField
                value={state.integrationData.integrationButtonColour}
                onChange={(color) => { handleDesignUpdate("buttonColour", color) }}
                id="p-integration-button-color"
                label="Button colour"
              />
            </Grid>
            <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationButtonTextColour') })}>
              <ColourPickerField
                value={state.integrationData.integrationButtonTextColour}
                onChange={(color) => { handleDesignUpdate("buttonTextColour", color) }}
                id="p-integration-button-txt-color"
                label="Button text colour"
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationButtonTextColour') })}>
              <ColourPickerField
                value={state.integrationData.integrationCategoryButtonBorderColor}
                onChange={(color) => { handleDesignUpdate("categoryButtonBorderColor", color) }}
                id="p-integration-category-button-border-color"
                label="Category button border colour"
              />
            </Grid>
            <Grid item xs={6} className={classNames({[classes.disabled]: !restrictions.includes('integrationItemTextColour') })}>
              <ColourPickerField
                value={state.integrationData.integrationItemTextColour}
                onChange={(color) => { handleDesignUpdate("integrationItemTextColour", color) }}
                id="p-integration-item-text-color"
                label="Category item name colour"
              />
            </Grid>
          </Grid>
        </Grid>
        {
          (
            restrictions.includes('integrationHideCopyright')
          ) && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5" component="h1">Additional settings</Typography>
              </Grid>
              <Grid
                item xs={12}
                spacing={3}
              >
                <Grid container style={{justifyContent: 'space-between'}}>
                  <Grid item>
                    <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}> Hide copyright © </Typography>
                  </Grid>
                  <Grid item>
                    {
                      !restrictions.includes('integrationHideCopyright') ? <PayButton setUpdating={setUpdating} feature={'integrationHideCopyright'} updating={updating} /> : (
                        <Switch
                          checked={state.integrationData.integrationHideCopyright}
                          onChange={e => dispatchState({ type: 'integration-data', payload: { integrationHideCopyright: !state.integrationData.integrationHideCopyright } })}
                          color="primary"
                          name="hide-copyright"
                          inputProps={{ 'aria-label': 'Hide copyright' }}
                          disabled={!restrictions.includes('integrationHideCopyright')}
                        />
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
              {
                showShoppingCart && (<UnpaidModal />)
              }
            </>
          )
        }
      </Grid>
      <Snackbar
        open={!!error}
        autoHideDuration={3000}
        onClose={() => setError(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        message={error}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </form>
  )
}

export default DesignStep
