import React, {useContext, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Select, Grid, Fab} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import CloseRounded from "@material-ui/icons/CloseRounded";
import axios from "axios";
import {useGlobalState} from "../../hooks/useGlobalState";
import CircularProgress from "@material-ui/core/CircularProgress";


export default props => {
  const {showModal, setShowModal} = props;
  const [selected, setSelected] = useState(null);
  const [sending, setSending] = useState(false);
  const [done, setDone] = useState(false);
  const state = useContext(useGlobalState.State);

  const handleSelection = e => {
    setSelected(e.target.value);
  };
  //requestMoreCalculations

  const submit = async () => {
    setSending(true);
    await axios.post(
     process.env.REACT_APP_API_URL + 'requestMoreCalculations',
     {
       email: state.signedInUser.data.user.email,
       calculations: selected
     },
     { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } })
     .then(result => {
       setSending(false);
       setDone(true)
     })
     .catch(error => setSending(false));
  }

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{fontFamily: 'futura-pt, sans-serif'}}
    >
      <Grid style={{display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-end'}}>
        <Fab
          color="primary"
          aria-label="Edit"
          size="small"
          onClick={handleClose}
          style={{border: '2px solid #00437C', borderRadius: 50}}
        >
          <CloseRounded />
        </Fab>
      </Grid>
      <DialogTitle id="alert-dialog-title" style={{marginTop: 0}}><span style={{fontSize: 28}}>Adding more calculators</span></DialogTitle>
      <DialogContent style={{display: 'flex', flexDirection: 'column', marginBottom: 15}}>
        <DialogContentText id="alert-dialog-description" style={{color: '#707070', fontSize: 20, marginBottom: 35}}>
          With your current plan, you can have up to 3 calculators. If you’d like to add more, let us know how many you’d like and we’ll be in touch shortly.
        </DialogContentText>
        <FormControl variant="outlined" className={{width: '100%'}}>
          <InputLabel id="size-item-select-outlined-label">Number of calculators</InputLabel>
          <Select
            labelId="integrations-select-outlined-label"
            id="integrations-select-outlined-label"
            value={selected}
            onChange={handleSelection}
            label="Number of calculators"
            name={'integrations'}
            style={{textAlign: 'left', width: '100%'}}
          >
            <MenuItem value=""></MenuItem>
            <MenuItem value={'1 - 9 calculators'}>1 - 9 calculators</MenuItem>
            <MenuItem value={'10 - 19 calculators'}>10 - 19 calculators</MenuItem>
            <MenuItem value={'20+ calculators'}>20+ calculators</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions style={{paddingRight: 24, paddingLeft: 24}}>
        {
          done ? (
            <p style={{color: '#1CB2E6', fontSize: 20, textAlign: 'center', width: '100%'}}>Great! We’ll be in contact soon to set this up</p>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              disabled={!selected}
              onClick={submit}
            >{
              sending ? (
                <CircularProgress color='inherit' size={15} />
              ) : 'Submit'
            }</Button>
          )
        }
      </DialogActions>
    </Dialog>
  )
}
