import React, {useContext, useEffect, useMemo} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  TextField,
  Button,
  Typography,
  Divider,
  Fab,
} from "@material-ui/core"
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';

// Core components
import { useGlobalState } from "hooks/useGlobalState"
// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import cloneDeep from "lodash.clonedeep";
import SortableList from "./SortableList";
import {mapPlansLimitations} from "../../../variables/InitState";
import ColourPickerField from "../../../components/ColourPickerField/ColourPickerField";

const useStyles = makeStyles(styles)

const CategoriesStepAdvanced = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);

  const { theRef } = props
  const cleanState = {
    ID: 0,
    catName: "",
    itemsAssigned: [],
    backgroundColor: "",
    colorText: ""
  }

  const onSortEnd = async ({newIndex, oldIndex}) => {
    const {arrayMoveImmutable} = (await import('array-move'));
    if (state.integrationData.integrationIsNewHelper) {
      const sortedCategory = {
        ...state.integrationData.integrationNewCategory,
        //items: newItems,
        itemsAssigned: arrayMoveImmutable(state.integrationData.integrationNewCategory.itemsAssigned, oldIndex, newIndex)
      }

      if (state.integrationData.integrationNewCategory.items) {
        const newItems = arrayMoveImmutable(state.integrationData.integrationNewCategory.items, oldIndex, newIndex);
        newItems.map((item, index) => {
          item.sort = index;
          return item;
        });
        sortedCategory.items = newItems;
      }

      //Save new
      dispatchState({
        type: 'integration-data',
        payload: {
          integrationNewCategory: sortedCategory,
        }
      });
      return
    }

    let otherCategories = state.integrationData.integrationCategories.filter(category => {
      return category.ID !== state.integrationData.integrationEditing;
    });

    const sortedCategory = {
      ...state.integrationData.integrationNewCategory,
      //items: newItems,
      itemsAssigned: arrayMoveImmutable(cloneDeep(state.integrationData.integrationNewCategory.itemsAssigned), oldIndex, newIndex)
    }

    const newCategories = [...otherCategories, sortedCategory];

    if (state.integrationData.integrationNewCategory.items) {
      const newItems = arrayMoveImmutable(cloneDeep(state.integrationData.integrationNewCategory.items), oldIndex, newIndex)
      newItems.map((item, index) => {
        item.sort = index;
        return item;
      });
      sortedCategory.items = newItems;
    }

    //Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationCategories: newCategories.sort((a,b) => a.sort - b.sort),
        integrationNewCategory: sortedCategory,
      }
    });
  };

  const saveNewCat = () => async () => {
    // Save new
    const newCategory = {...state.integrationData.integrationNewCategory}
    newCategory.sort = state.integrationData.integrationCategories.length;
    const newCategories = [...state.integrationData.integrationCategories, newCategory].sort((a,b) => a.sort - b.sort);
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationCategories: newCategories,
        integrationNewCategory: { ...cleanState }
      }
    });

    //await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationSecPanel: false, integrationCategories: newCategories, integrationNewCategory: { ...cleanState } } }, false, dispatchState);
  }

  const saveCat = () => async () => {

    // loop through the category items and set the new one
    let otherCategories = state.integrationData.integrationCategories.filter(category => {
      return category.ID !== state.integrationData.integrationEditing;
    });
    //Save new
    const newCategories = [...otherCategories, state.integrationData.integrationNewCategory].sort((a,b) => a.sort - b.sort);

    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationCategories: newCategories,
        integrationNewCategory: { ...cleanState }
      }
    });

  }

  /* eslint-disable */
  useEffect(() => {
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  const getCategoryItems = () => {
    // get category name from the currently editing category
    let category = state.integrationData.integrationCategories.filter(category => {
      return category.ID === state.integrationData.integrationEditing;
    });
    // get the item ids from the category and filter them from the categories list
    if (category && category[0]) {
      const itemsAssigned = state.integrationData.integrationNewCategory.itemsAssigned;
      let items = state.integrationData.itemLibrary.filter((prop, index) => {
        return itemsAssigned.includes(prop.ID);
      });
      items.map(item => {
        const index = (category[0].items || []).findIndex(elem => elem.id === item.id);
        if (index !== -1) {
          item.sort = index;
        }
        return item;
      });
      let itemsNotInAnyCategory = getItemsNotInCategories();
      itemsNotInAnyCategory.map(elem => {
        if ('sort' in elem) delete elem.sort
      });
      return [...items.sort((a,b)=> a.sort - b.sort), ...itemsNotInAnyCategory];
    }
    return [];

  };

  const handleItemsForNewCategory = items => {
    if (!state.integrationData.integrationIsNewHelper || !state.integrationData.integrationNewCategory?.items)
      return items;

    const inCategory = [];
    const notInCategory = [];
    items.map(item => {
      const index = state.integrationData.integrationNewCategory.itemsAssigned.indexOf(item.ID);
      if (index !== -1 && 'sort' in state.integrationData.integrationNewCategory.items?.[index]) {
        inCategory.push({...item, sort: state.integrationData.integrationNewCategory.items[index].sort});
        return;
      }
      notInCategory.push(item);
    });
    return [...inCategory.sort((a, b) => a.sort - b.sort), ...notInCategory]
  }

  const getItemsNotInCategories = () => {
    // get unique item ids in all categories
    let itemsInCategories = [];
    state.integrationData.integrationCategories.forEach(category => {
      itemsInCategories = [...itemsInCategories, ...category.itemsAssigned];
    });
    const itemsAssigned = state.integrationData.integrationNewCategory.itemsAssigned;
    itemsInCategories = [...new Set(itemsInCategories)];
    // loop through the whole list if items and get the ones which are not assigned to any category.
    let itemsNotInCategories = state.integrationData.itemLibrary.filter(item => {
      return !itemsAssigned.includes(item.ID);
    });
    const sortedNotInCategories = itemsNotInCategories.sort((a,b)=> a.sort - b.sort);
    return itemsNotInCategories && itemsNotInCategories[0] ? sortedNotInCategories  : [];
  };
  //
  // const itemsForNewCategory = useMemo(()=>getCategoryItems(),
  //   [
  //     state.integrationData.integrationNewCategory.itemsAssigned.length,
  //     state.integrationData.integrationNewCategory.itemsAssigned,
  //     state.integrationData.itemLibrary
  //   ])
  const categoryItems = useMemo(()=>getCategoryItems(),
    [
      state.integrationData.integrationNewCategory.itemsAssigned.length,
      state.integrationData.integrationNewCategory.itemsAssigned,
      state.integrationData.itemLibrary
    ]);

  return (
    <Grid spacing={3} container>
      <Fab
        color="primary"
        aria-label="Save"
        size="medium"
        className={classes.closeFab}
        style={{position: 'fixed', right: 100}}
        onClick={state.integrationData.integrationIsNewHelper ? saveNewCat() : saveCat()}
        disabled={
          state.integrationData.integrationNewCategory.catName === "" ||
          state.integrationData.integrationNewCategory.itemsAssigned.length === 0
        }
      >
        <SaveRoundedIcon />
      </Fab>
      <Grid item xs={12}>
        <Typography variant="h3" component="h2">{state.integrationData.integrationIsNewHelper ? "New category" : "Edit category"}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.customDividerOne} style={{ marginBottom: 20 }} />
        <TextField
          id="p-integration-category-name"
          label="Category name"
          variant="outlined"
          type="text"
          value={state.integrationData.integrationNewCategory.catName}
          onChange={(e) => { dispatchState({ type: 'integration-data', payload: { integrationNewCategory: { ...state.integrationData.integrationNewCategory, catName: e.target.value } } }) }}
          fullWidth
          inputProps={{ maxLength: 20 }}
          helperText={state.integrationData.integrationNewCategory.catName ? `${state.integrationData.integrationNewCategory.catName.length} / 20 character limit` : 'Category name is required'}
          error={!state.integrationData.integrationNewCategory.catName}
          disabled={!restrictions.includes('catName')}
        />
      </Grid>
      <Grid item xs={12} className={classes.colorsWrap}>
        <Divider className={classes.customDividerOne} />
        <Typography variant="subtitle1" component="h2" className={classes.subHeadOne}>Label colours</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <ColourPickerField
              value={state.integrationData.integrationNewCategory.colorText}
              onChange={color => {
                dispatchState({
                  type: 'integration-data',
                  payload: {
                    integrationNewCategory: { ...state.integrationData.integrationNewCategory, colorText: color  } } }) }}
              id="p-text-color"
              label="Text colour"
            />
          </Grid>
          <Grid item xs={6}>
            <ColourPickerField
              value={state.integrationData.integrationNewCategory.backgroundColor}
              onChange={color => {
                dispatchState({
                  type: 'integration-data',
                  payload: {
                    integrationNewCategory: { ...state.integrationData.integrationNewCategory,   backgroundColor: color  } } }) }}
              id="p-background-color"
              label="Background colour"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Assign items to your category</Typography>
      </Grid>
      <Grid item xs={12}>
        <SortableList onSortEnd={onSortEnd} items={categoryItems} />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          className={classes.buttonSpacing}
          color="primary"
          size="large"
          fullWidth
          onClick={state.integrationData.integrationIsNewHelper ? saveNewCat() : saveCat()}
          disabled={state.integrationData.integrationNewCategory.catName === "" || state.integrationData.integrationNewCategory.itemsAssigned.length === 0}
        >Save{state.integrationData.integrationIsNewHelper ? " new " : " "}category</Button>
      </Grid>
    </Grid>
  )
}

export default CategoriesStepAdvanced
