import axios from "axios";
import { freeAccounts } from "./common";
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const regexp = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
export const regexpLocalhost = /^http(s)?\:\/\/localhost\:\d{1,4}(\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*))?|^localhost\:\d{1,4}(\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*))?|^localhost$^http(s)?\:\/\/localhost\:\d{1,4}(\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*))?|^localhost\:\d{1,4}(\/([-a-zA-Z0-9()@:%_\+.~#?&\/=]*))?|^localhost$/;
export const validatePhoneNumberRegex = /^\d{9,10}$/;
export const allowedStorageTypes = [
  'self-storage',
  'container-storage',
  'removals-storage',
  'mobile-storage',
  'portable-storage',
  'indoor-storage',
  'kiosks',
  'portable-storage',
  'movers',
  'complete-kiosks' //HOTFIX
];

export const couponRegexp = /^\w{1,15}\d{1,3}$/g;

export const isPaidSubscription = state => {
  if (freeAccounts.includes(state.signedInUser?.data?.user?.email)) {
    return true; // Allow using without card details
  }
  
  // free usage by pricing plan
  if (
    [
      process.env.REACT_APP_STRIPE_STRAGELY_PRICE,
    ].includes(state.signedInUser?.data?.user?.lastChosenSubscription)
  ) {
    return true;
  }

  const subscription = state.signedInUser?.data?.user?.stripeAccount?.subscriptionId ?
    true :
    false;
  return !!subscription
}

export const isFreePlan = state => {
  return [
    process.env.REACT_APP_STRIPE_BASIC_PLAN,
    process.env.REACT_APP_STRIPE_BASIC_PRICE_MONTHLY,
    process.env.REACT_APP_STRIPE_ESSENTIAL_PLAN
  ].includes(state.signedInUser?.data?.user?.lastChosenSubscription)
}

export const freeItems = [
  "CHAIR-LIVINGROOM-13",
  "DININGTABLE46-LIVINGROOM-11",
  "DININGTABLE8-LIVINGROOM-12",
  "2SEATSOFA-LIVINGROOM-15",
  "3SEATSOFA-LIVINGROOM-16",
  "ARMCHAIR-LIVINGROOM-14",
  "COFFEETABLE-LIVINGROOM-10",
  "SMALLTABLE-LIVINGROOM-9",
  "BOOKCASE-LIVINGROOM-20",
  "TV-LIVINGROOM-17",
  "TVUNIT-LIVINGROOM-18",
  "BEDSIDETABLE-BEDROOM-5",
  "DRAWERS-BEDROOM-6",
  "DESK-BEDROOM-8",
  "BEDBASEENSEMBLE-BEDROOM-3",
  "QUEENKINGMATTRESS-BEDROOM-2",
  "BEDBASEFRAME-BEDROOM-4",
  "FRIDGE-KITCHEN-23",
  "MICROWAVE-KITCHEN-27",
  "STOOL-KITCHEN-28",
  "WASHINGMACHINE-KITCHEN-24",
  "SMALLBOX-OTHER-29",
  "MEDIUMBOX-OTHER-30",
  "LARGEBOX-OTHER-31",
  "STORAGE-TOTE",
  "BBQ-OTHER-33",
  "SURFBOARD-OTHER-36",
  "ARCADE-OTHER-35"
]

export const selfType = [
  'self-storage',
  'container-storage',
  'removals-storage',
  'portable-storage'
];

export async function validateCoupon(coupon, token) {
  try {

    const response =  await axios.post(process.env.REACT_APP_API_URL+ 'checkCoupon', {
      coupon
    }, {
      headers: {Authorization: "Bearer " + token}
    })

    return response;
  } catch (e) {
    console.log('e:', e)
  }
}

export const isValidPhoneNumber = (value = '') =>
  validatePhoneNumberRegex.test(value.replace(/\D/g, ''));
