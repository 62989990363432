import React, {useContext, useEffect, useMemo, useState} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar
} from "@material-ui/core"

// Core components
import classNames from 'classnames'
import { useGlobalState } from "hooks/useGlobalState"
import useIsIntegrationChanges from "../../helpers/useIsIntegrationChanges";
import { Link, useHistory } from "react-router-dom";
import axios from 'axios';
import { handleError } from '../../helpers/SaveIntegration';
// Other components
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle";

import { sendAdminProgressEmail } from '../../helpers/SendEmail';
import {saveIntegration as genericSaveIntegration} from '../../helpers/SaveIntegration';
import Alert from "@material-ui/lab/Alert";
import {isPaidSubscription, isFreePlan} from "../../helpers/validation";
import { freeAccounts } from "helpers/common";

const useStyles = makeStyles(styles)

const IntegrationStepper = props => {
  const classes = useStyles()
  const state = useContext(useGlobalState.State);
  let history = useHistory();
  const { urlParam, setUpdating, updating } = props
  const [openDialog, setOpenDialog] = useState(false) //local state
  const [openDialogSave, setOpenDialogSave] = useState(false) //local state
  const dispatchState = useContext(useGlobalState.Dispatch);
  const [error, setError] = useState(null)
  const [isPaymentSetUp, setIsPaymentSetUp] = useState(false);
  const isPaid = useMemo(() => isPaidSubscription(state), [
    state.signedInUser?.data?.user?.stripeAccount,
  ]);
  const isSubscriptionFree = useMemo(() => isFreePlan(state), [state.signedInUser?.data?.user?.lastChosenSubscription]);

  useEffect(() => {
    setIsPaymentSetUp(isPaid);
  }, [])
  useEffect(()=>{
    if ( state.signedInUser?.data?.user?.stripeAccount?.subscriptionId )
    {
      setIsPaymentSetUp(true)
    }
  }, [state.signedInUser?.data?.user?.stripeAccount])

  const saveIntegration = async (isDetails = false, complete = false) => {
    setUpdating(true);

    try {
      const currentState = complete ?
        { ...state,
          signedInUser: {
          ...state.signedInUser,
            data: {
            ...state.signedInUser.data,
              user: {...state.signedInUser.data.user, registrationActivity: 'Integration Created'}}}} :
        state;

      const response = await genericSaveIntegration(currentState, isDetails, dispatchState);
      if (!response?.status) {
        setUpdating(false);
        throw new Error('Saving failed');
      }
      setUpdating(false);
    } catch (e) {
      handleError(e);
      console.log('e', e)
      setUpdating(false);
      throw new Error(e)
    }
  };

  const isValidaPaymentInfo = () => (state.currentPlan !== 'Essential' && (
    !state.userData.billing.paymentMethod ||
    state.userData.billing.invalidCard ||
    state.userData.billing.firstName === '' ||
    state.userData.billing.lastName === '' ||
    state.userData.billing.address === '' 
    //|| !isValidPhoneNumber(state.userData.billing.company)
    )
  )


  const handleStepComplete = async (step, skip) => {
    let url = "";
    const isDetails = !skip && step === 'details';
    if (isDetails && !isFreePlan(state)) setIsPaymentSetUp(true);

    switch (step) {
      case "initial":
        url = "/admin/my-calcumate/new?step=units";
        break;
      case "units":
        if (state.integrationData.integrationShowUnitsNote) {
          dispatchState({ type: 'integration-data', payload: { integrationShowUnitsNote: false } })
        }
        url = "/admin/my-calcumate/new?step=categories";
        break;
      case "categories":
        url = "/admin/my-calcumate/new?step=presets";
        break;
      case "presets":
        url = "/admin/my-calcumate/new?step=design";
        break;
      case "design":
        url = isPaymentSetUp ? "/admin/my-calcumate/new?step=confirm" : "/admin/my-calcumate/new?step=details";
        break;
      case "details":
        url = "/admin/my-calcumate/new?step=confirm";
        break;
      case "confirm":
        if (state.newIntegration || state.integrationData.integrationPublished === false) {
          // send the notification only if this is a new integration
          await sendAdminProgressEmail(3, {
            firstName: state.signedInUser.data.user.firstName,
            lastName: state.signedInUser.data.user.lastName,
            phone: state.signedInUser.data.user.phone,
            email: state.signedInUser.data.user.email,
            company: state.signedInUser.data.user.companyName,
            domain: state.integrationData.integrationDomain,
            serviceNumber: state.serviceNumber,
            currency: state.currency,
            plan: `${state.currentPlan} / ${state.signedInUser?.data?.user?.lastChosenSubscription}`,
            pricing: state.signedInUser?.data?.user?.lastChosenSubscription,
            service: state.service,
            coupon: state?.userData?.billing?.coupon ?
              state.userData.billing.coupon :
              state?.signedInUser?.data?.billing?.coupon
          });
        }
        url = "/admin/my-calcumate/new?step=confirmed";
        break;
    }
    try {
      await saveIntegration(isDetails, step === "confirm" && (state.newIntegration || state.integrationData.integrationPublished === false));
      history.push(url);
    } catch (e) {
      setError('Saving error');
      if (step === 'details') {
        dispatchState({ type: 'reg-state', payload: { userData: { billing: { paymentMethod: null } } } })
        setIsPaymentSetUp(false);
      }
      setUpdating(false);
      console.log('Saving error:', e);
    }
  }

  const handleFinalStep = () => {
    // update the state with new integration data
    axios.post(
      process.env.REACT_APP_API_URL + 'user/get',
      {
        cukey:  state?.adminMode?.cuid || state.signedInUser.cukey,
        customerEmail: state?.adminMode?.cuemail 
      },
      { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
    ).then(response => {
      if (response && response.data && response.status === 200) {
        dispatchState({
          type: 'root-state',
          payload: {
            signedInUser: {
              ...state.signedInUser, data: response.data
            }
          }
        });
      }
    }).catch(error => {
      console.error(error);

    })
    history.push('/admin/my-calcumate/');
  }

  const prevButton = () => {
    switch (urlParam) {
      case "?step=units":
        return <Button
          disabled={updating}
          variant="contained"
          component={Link}
          to={'/admin/my-calcumate/new?step=options'}
          size="medium"
          style={{ marginRight: 15 }}
          onClick={e => {
            e.preventDefault();
            if (state.integrationData.integrationShowUnitsNote) {
              dispatchState({ type: 'integration-data', payload: { integrationShowUnitsNote: false } })
            }
            history.push('/admin/my-calcumate/new?step=options');
          }}
        >Back</Button>

      case "?step=categories":
        return <Button disabled={updating} variant="contained" component={Link} to={'/admin/my-calcumate/new?step=units'} size="medium" style={{ marginRight: 15 }}>Back</Button>

      case "?step=presets":
        return <Button disabled={updating} variant="contained" component={Link} to={'/admin/my-calcumate/new?step=categories'} size="medium" style={{ marginRight: 15 }}>Back</Button>

      case "?step=design":
        return <Button disabled={updating} variant="contained" component={Link} to={'/admin/my-calcumate/new?step=presets'} size="medium" style={{ marginRight: 15 }}>Back</Button>
      case "?step=details":
        return <Button disabled={updating} variant="contained" component={Link} to={'/admin/my-calcumate/new?step=design'} size="medium" style={{ marginRight: 15 }}>Back</Button>
      case "?step=confirm":
        return <Button disabled={updating} variant="contained" component={Link} to={isPaymentSetUp ? '/admin/my-calcumate/new?step=design' : '/admin/my-calcumate/new?step=details'} size="medium" style={{ marginRight: 15 }}>Back</Button>

      case "?step=confirmed":
        return null

      default:
        return null
    }
  }

  const exitButton = () => {
    switch (urlParam) {
      case "?step=units":
        return <Button disabled={updating} variant="outlined" size="medium" onClick={() => { setOpenDialogSave(true) }}>Exit and save</Button>

      case "?step=categories":
        return <Button disabled={updating} variant="outlined" size="medium" onClick={() => { setOpenDialogSave(true) }}>Exit and save</Button>

      case "?step=presets":
        return <Button disabled={updating} variant="outlined" size="medium" onClick={() => { setOpenDialogSave(true) }}>Exit and save</Button>

      case "?step=design":
        return <Button disabled={updating} variant="outlined" size="medium" onClick={() => { setOpenDialogSave(true) }}>Exit and save</Button>

      case "?step=confirm":
        return <Button disabled={updating} variant="outlined" size="medium" onClick={() => { setOpenDialogSave(true) }}>Exit and save</Button>

      case "?step=confirmed":
        return <Button disabled={updating} variant="contained" component={Link} to={'/admin/my-calcumate/new'} size="medium">Edit</Button>

      case "?step=details":
        return <Button disabled={updating} variant="outlined" size="medium" onClick={() => { setOpenDialogSave(true) }}>Exit and save</Button>

      default:
        if (state.integrationDataOptions.updatingItems || state.integrationData.integrationActiveLanguage === "other" || !state.integrationData.integrationName || state.integrationData.integrationCtaURL === "" || state.integrationData.integrationDomain === "" || (state.integrationData.integrationCtaURL !== "" && !state.integrationData.ctaUrlValid) || (state.integrationData.integrationDomain !== "" && !state.integrationData.domainUrlValid) || state.integrationData.integrationMeasurement === "" || state.integrationData.integrationStorageType === "") {
          return <Button variant="outlined" size="medium" onClick={() => { setOpenDialog(true) }}>Exit</Button>
        } else {
          return <Button disabled={updating} variant="outlined" size="medium" onClick={() => { setOpenDialogSave(true) }}>Exit and save</Button>
        }
    }
  }

  const basicValidationRules =
    state?.integrationDataOptions?.updatingItems ||
      state?.integrationData?.integrationActiveLanguage === "other" ||
      !state?.integrationData?.integrationName ||
      state?.submittingUserData ||
      (!isSubscriptionFree && !isPaid && state?.integrationData?.integrationCtaURL === "") ||
      state?.integrationData?.integrationDomain === "" ||
      (state?.integrationData?.integrationCtaURL !== "" && !state?.integrationData?.ctaUrlValid) ||
      (state?.integrationData?.integrationDomain !== "" && !state?.integrationData?.domainUrlValid) ||
      state?.integrationData?.integrationMeasurement === "" || state?.integrationData?.integrationStorageType === "" ||
      updating;

  const isIntegrationDataChanged = useIsIntegrationChanges();

  const nextButton = () => {
    switch (urlParam) {
      case "?step=units":
        return (
          <Button variant="contained" onClick={() => handleStepComplete("units")} disabled={!state.integrationData.integrationName || state.submittingUserData || state.integrationData.integrationUnits.length === 0 || updating} size="medium">{state.submittingUserData || updating ? <CircularProgress color='inherit' size={15} /> : 'Next'}</Button>
        )
      case "?step=categories":
        return (
          <Button variant="contained" onClick={() => handleStepComplete("categories")} disabled={!state.integrationData.integrationName || state.submittingUserData || state.integrationData.integrationCategories.length === 0 || updating} size="medium">{state.submittingUserData || updating ? <CircularProgress color='inherit' size={15} /> : 'Next'}</Button>
        )
      case "?step=presets":
        return (
          <Button variant="contained" onClick={() => handleStepComplete("presets")} disabled={!state.integrationData.integrationName || state.submittingUserData || updating} size="medium">{state.submittingUserData || updating ? <CircularProgress color='inherit' size={15} /> : 'Next'}</Button>
        )
      case "?step=design":
        return (
          <Button variant="contained" onClick={() => handleStepComplete("design")} disabled={!state.integrationData.integrationName || state.submittingUserData || updating} size="medium">{state.submittingUserData || updating ? <CircularProgress color='inherit' size={15} /> : 'Next'}</Button>
        )
      case "?step=details":
        return (
          <Button variant="contained" onClick={() => handleStepComplete("details")} disabled={(isValidaPaymentInfo() || updating || basicValidationRules) && !isSubscriptionFree} size="medium">{state.submittingUserData || updating ? <CircularProgress color='inherit' size={15} /> : 'Next'}</Button>
        )
      case "?step=confirm":
        return (
          <Button 
            variant="contained" 
            onClick={() => handleStepComplete("confirm")} 
            disabled={!state.integrationData.integrationName || updating} 
            size="medium">
              {updating ? <CircularProgress color='inherit' size={15} /> : 'Publish'}
          </Button>
        )
      case "?step=confirmed":
        return (
          // <Button variant="contained" component={Link} to={'/admin/my-calcumate/'} size="medium">Done</Button>
          <Button variant="contained" onClick={handleFinalStep} disabled={!state.integrationData.integrationName || updating} size="medium">Done</Button>
        )
      default:
        return (
          <Button
            variant="contained"
            onClick={() => handleStepComplete('initial')}
            disabled={basicValidationRules} size="medium"
          >
            {state.submittingUserData || updating ?
              <CircularProgress color='inherit' size={15} /> : 'Next'}
          </Button>
        )
    }
  }

  const handleIntegrationSave = async () => {
    await saveIntegration();

    dispatchState({
      type: "integration-data-options",
      payload: {
        selectedIntegration: 0,
      },
    });

    setTimeout(() => {
      history.push("/admin/my-calcumate");
    }, 500);

  }

  const fastTravel = async step => {
    if (step === 'options' && urlParam === '?step=details' /* && !isValidaPaymentInfo() && !isPaid && basicValidationRules*/) {
      history.push(`/admin/my-calcumate/new?step=${step}`);
    }
    if (basicValidationRules) return;
    if (
      !isSubscriptionFree &&
      ['confirm'].includes(step) &&
      !isPaid &&
      !freeAccounts.includes(state.signedInUser?.data?.user?.email)
    ) {
      return;
    }

    if (isIntegrationDataChanged)
      await saveIntegration();
    history.push(`/admin/my-calcumate/new?step=${step}`);
  }

  const filteredIntegrationStepperSteps = state.integrationData.integrationStepperSteps
    .filter(elem => isPaymentSetUp && elem === 'Details' ? false : true)
  return (
    <div className={classNames(state.integrationData.integrationStepperStep === 0 ? classes.stepperIndex : classes.stepperIndex2, { [classes.stepperHidden]: state.integrationDataOptions.integrationPanelsFS })}>
      <Grid
        container
        spacing={0}
      >
        <Grid item xs={12} style={{ paddingRight: 20, paddingLeft: 20 }}>
          <Stepper activeStep={state.integrationData.integrationStepperStep} alternativeLabel className={classes.customStepper}>
            {filteredIntegrationStepperSteps.map((label, index) => {
              return (
                <Step key={label} className={classNames(classes.integrationStep, {
                  [classes.disableStep]: index > 0 && basicValidationRules,
                })}>
                  {
                    (
                      (urlParam === '?step=details') &&
                      label.toLowerCase() === 'options' &&
                      !isPaid &&
                      basicValidationRules &&
                      !state.integrationDataOptions.updatingItems &&
                      state?.integrationData?.id && !updating
                    )  ?
                      <StepLabel StepIconComponent={() => <ErrorIcon style={{color:'#FDADBF'}} /> } onClick={() => fastTravel(label.toLowerCase())}>{label}</StepLabel> :
                      <StepLabel onClick={() => fastTravel(label.toLowerCase())}>{label}</StepLabel>
                  }
                </Step>
              )
            })}
          </Stepper>
        </Grid>
        <Grid item xs={12} className={classes.sideBarActions}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            spacing={0}
          >
            <Grid item>
              {exitButton()}
            </Grid>
            <Grid item>
              {prevButton()}
              {nextButton()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Closing save dialog */}
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to leave?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By leaving, you won't keep any of the data saved.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => { setOpenDialog(false) }} color="primary">No</Button>
          <Button size="large" component={Link} to="/admin/my-calcumate" color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
      {/* Closing no-save dialog */}
      <Dialog
        open={openDialogSave}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to leave?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your calculator will be saved up to this point.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => { setOpenDialogSave(false) }} color="primary">No</Button>
          <Button size="large" onClick={handleIntegrationSave} color="primary">{updating ? <CircularProgress color='inherit' size={15} /> : 'Yes'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        message={error}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default IntegrationStepper
