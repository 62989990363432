import React, {useContext, useEffect, useMemo, useState} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Typography,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Fab,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Popover,
  Switch, FormControl, InputLabel, Select, MenuItem
} from "@material-ui/core";
import EditRounded from '@material-ui/icons/EditRounded';
import DeleteForeverRounded from '@material-ui/icons/DeleteForeverRounded';
import Alert from '@material-ui/lab/Alert';
import HelpRounded from "@material-ui/icons/HelpRounded";
import ReorderRoundedIcon from '@material-ui/icons/ReorderRounded';
// Core components
import { useGlobalState } from "hooks/useGlobalState";
import classNames from "classnames";

// Other components
import { saveIntegration } from '../../../helpers/SaveIntegration';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from "react-sortable-hoc";
import cloneDeep from 'lodash.clonedeep';
// Style
import styles from "assets/jss/material-dashboard-react/layouts/integrationStyle"
import UnpaidModal from "../../FreeVersion/Modal";
import {mapPlansLimitations} from "../../../variables/InitState";
import PayButton from "../../FreeVersion/Button";

const useStyles = makeStyles(styles)

const CategoriesStep = props => {
  const classes = useStyles();
  const dispatchState = useContext(useGlobalState.Dispatch)
  const state = useContext(useGlobalState.State)
  const [openDialog, setOpenDialog] = useState(false) //local state
  const [deletingCategory, setDeletingCategory] = useState(null) //local state
  const [categoryPop, setCategoryPop] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const [customItemPop, setCustomItemPop] = useState(null);
  const { theRef } = props
  const cleanState = {
    ID: 0,
    catName: "",
    itemsAssigned: []
  }

  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], []);
  const DragHandle = SortableHandle(() => <ReorderRoundedIcon style={{fontSize: 20}} />);
  const categoriesAmount = useMemo(() => {
    const categories = cloneDeep(state.integrationData.integrationCategories)
    const sorted = categories.sort((a, b) => a.ID > b.ID ? 1 : -1);
    return sorted.length ? (sorted?.[sorted.length - 1]?.ID || sorted.length) : 0
  },[state.integrationData.integrationCategories.length]);


  const getEditingCategory = categoryId => {
    let editingCategories = state.integrationData.integrationCategories.filter(category => {
      return category.ID === categoryId;
    });

    return editingCategories[0];
  };

  const editCat = ID => () => {
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationIsNewHelper: false,
        integrationSecPanel: true,
        integrationEditing: ID,
        integrationNewCategory: { ...getEditingCategory(ID) }
      }
    })
  }

  const createCat = () => () => {
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: true,
        integrationIsNewHelper: true,
        integrationNewCategory: { ...cleanState, ID: categoriesAmount + 1, sort: categoriesAmount  }
      }
    })
  }

  const deleteCategory = id => {
    setDeletingCategory(id);
    setOpenDialog(true);
  }

  const deleteCat = () => async () => {
    setOpenDialog(false) //Close modal
    // get the categories excluding the selected one and set it in the session
    let excludedCategories = state.integrationData.integrationCategories.filter(category => {
      return category.ID !== deletingCategory;
    });
    //Save new
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationSecPanel: false,
        integrationCategories: excludedCategories,
        integrationNewCategory: { ...cleanState }
      }
    });

    await saveIntegration({ ...state, integrationData: { ...state.integrationData, integrationSecPanel: false, integrationCategories: excludedCategories, integrationNewCategory: { ...cleanState } } }, false, dispatchState);
  }

  /* eslint-disable */
  useEffect(() => {
    dispatchState({ type: 'integration-data', payload: { integrationStepperStep: 2 } }) // Set stepper step number 3
    theRef.current.scrollTop = 0 //scrollTop back to 0 when changing views
  }, []);
  /* eslint-enable */

  const getItemsNotInCategories = () => {
    // get unique item ids in all categories
    let itemsInCategories = [];
    state.integrationData.integrationCategories.forEach(category => {
      itemsInCategories = [...itemsInCategories, ...category.itemsAssigned];
    });

    itemsInCategories = [...new Set(itemsInCategories)];

    // loop through the whole list if items and get the ones which are not assigned to any category.
    let itemsNotInCategories = state.integrationData.itemLibrary.filter(item => {
      return !itemsInCategories.includes(item.ID);
    });

    return itemsNotInCategories && itemsNotInCategories[0] ? itemsNotInCategories[0] : [];
  };

  useEffect(() => {
    if (!firstLoad && !state.integrationData.integrationSecPanel) {
      const timeout = setTimeout(async () => {
        props.setUpdating(true);
        try {
          await saveIntegration({ ...state, integrationData: { ...state.integrationData } }, false, dispatchState);
        } catch (e) {
          console.log('Saving error:', e)
        }
        props.setUpdating(false);
      }, 1000);
      return () => clearTimeout(timeout);
    }
    setFirstLoad(false);
  }, [
    state.integrationData.integrationCategories,
    state.integrationData.integrationDisplaySearch,
    state.integrationData.integrationAddCustomItems,
    state.integrationData.integrationItemsListMode
  ])
  const onSortEnd = async ({newIndex, oldIndex}) => {
    const {arrayMoveImmutable} = (await import('array-move'));
    const newCategories = arrayMoveImmutable(cloneDeep(state.integrationData.integrationCategories), oldIndex, newIndex)
    newCategories.map((category, index) => {
      category.sort = index;
      return category;
    })
    dispatchState({
      type: 'integration-data',
      payload: {
        integrationCategories: newCategories,
      }
    });
  };
  
  const SortableItem = SortableElement(({ prop, index }) => (
    <TableRow key={index} className={'categoryItem'}>
      <TableCell align="left">
        <DragHandle />
      </TableCell>
      <TableCell align="left">
        <Typography variant="subtitle2" component="p">{prop.catName}</Typography>
      </TableCell>
      <TableCell align="right">
        <Tooltip title="Edit category" aria-label="Edit category">
          <Fab
            color="primary"
            aria-label="Edit category"
            size="small"
            onClick={editCat(prop.ID)}
            disabled={!restrictions.includes('editCategory')}
          >
            <EditRounded />
          </Fab>
        </Tooltip>
        <Tooltip title="Delete category" aria-label="Delete category">
          <Fab
            color="primary"
            aria-label="Delete category"
            size="small"
            onClick={() => { deleteCategory(prop.ID) }}
            className={classes.deleteIconColour}
            disabled={!restrictions.includes('removeCategory')}
          >
            <DeleteForeverRounded />
          </Fab>
        </Tooltip>
      </TableCell>
    </TableRow>
  ));

  const SortableWrap = SortableContainer(({ children }) => {
    return (
      <Table className={classes.integrationTable} aria-label="Category setup">
        <TableBody>
          {children}
        </TableBody>
      </Table>
    )
  });

  return (
    <form noValidate autoComplete="off" className={classNames(classes.standardForm, {[classes.pointerEventNone]: !restrictions.includes('integrationCreateCategory') })}>
      {
        !restrictions.includes('integrationCreateCategory') && (<UnpaidModal />)
      }
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h1">Categories <HelpRounded style={{ verticalAlign: "sub", fontSize: "1.3rem" }} onClick={(event) => { setCategoryPop(event.currentTarget) }} /></Typography>
          <Popover
            id="domainURL"
            open={Boolean(categoryPop)}
            anchorEl={categoryPop}
            onClose={() => { setCategoryPop(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography variant="body2" className={classes.blueText}>Categories refer to common groups of items so the user can find items to load to calculate their optimal storage unit size. We’ve recommended common categories for you to use although you are welcome to edit these if you prefer. <br /><br />Please note you can add a minimum of 1 and a maximum of 5 categories to each Calcumate integration due to display limitations.  You must also have unselected items to add a new category. Otherwise, the “next” and “add category” buttons will be disabled.</Typography>
          </Popover>
        </Grid>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h6" component="h4">
              <span
                style={{ color: 'rgba(0,0,0,.87)', fontWight: 500, fontSize: 14}}
                className={classNames({[classes.disabled]: !restrictions.includes('integrationDisableMultipleResults')})}
              >
                Display search function?
              </span>
          </Typography>
          <Grid item>
            {
              !restrictions.includes('integrationDisplaySearch') ?
                <PayButton setUpdating={props.setUpdating} feature={'integrationDisplaySearch'} updating={props.updating} /> :
                (
                  <Switch
                    checked={state.integrationData.integrationDisplaySearch}
                    onChange={
                      async () => {
                      dispatchState({
                        type: 'integration-data',
                        payload: {integrationDisplaySearch: !state.integrationData.integrationDisplaySearch}
                      });
                    }
                    }
                    color="primary"
                    name="search-function"
                    inputProps={{ 'aria-label': 'search function' }}
                  />
                )
            }
          </Grid>
        </Grid>
        <Grid item xs={12} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography variant="h6" component="h4">
              <span
                style={{ color: 'rgba(0,0,0,.87)', fontWight: 500, fontSize: 14}}
                className={classNames({[classes.disabled]: !restrictions.includes('integrationAddCustomItems')})}
              >
                Add custom items?
              </span>
            <HelpRounded
              style={{ verticalAlign: "sub", fontSize: "1.3rem", marginLeft: 5 }}
              onClick={(event) => { setCustomItemPop(event.currentTarget) }} />
          </Typography>
          <Popover
            id="domainURL"
            open={Boolean(customItemPop)}
            anchorEl={customItemPop}
            onClose={() => { setCustomItemPop(null) }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Typography variant="body2" className={classes.blueText}>Give customers the option to add an item not in our list, setting their own dimensions.</Typography>
          </Popover>
          <Grid item>
            {
              !restrictions.includes('integrationAddCustomItems') ?
                <PayButton setUpdating={props.setUpdating} feature={'integrationAddCustomItems'} updating={props.updating} /> :
                (
                  <Switch
                    checked={state.integrationData.integrationAddCustomItems}
                    onChange={
                      async () => {
                        dispatchState({
                          type: 'integration-data',
                          payload: {integrationAddCustomItems: !state.integrationData.integrationAddCustomItems}
                        });
                      }
                    }
                    color="primary"
                    name="add-item"
                    inputProps={{ 'aria-label': 'add item' }}
                  />
                )
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor="p-integration-language">Display items mode (Mobile only)</InputLabel>
            <Select
              id="p-integration-items-list-mode"
              value={state.integrationData.integrationItemsListMode || 'slider'}
              variant="outlined"
              label="Display items mode (Mobile only)"
              onChange={(e) => {
                dispatchState({
                  type: 'integration-data',
                  payload: {integrationItemsListMode: e.target.value}
                });
              }}
              disabled={!restrictions.includes('integrationItemsListMode')}
            >
              <MenuItem value="slider">Horizontal slider</MenuItem>
              <MenuItem value="list">Popup menu</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <div>
            <SortableWrap onSortEnd={onSortEnd} useDragHandle>
              {state.integrationData.integrationCategories.length ? state.integrationData.integrationCategories.map((prop, index) => (
                <SortableItem key={`item-${index}`} index={index} prop={prop} disabled={!restrictions.includes('sortingCategory')} />
              )) : <Alert severity="error">Please add a category to continue. </Alert>}
            </SortableWrap>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            className={classes.buttonSpacing}
            color="secondary"
            size="medium"
            fullWidth
            onClick={createCat()}
            disabled={
              state.integrationData.integrationCategories.length >= 6 ||
              (state.integrationData.integrationCategories.length < 6 &&
                getItemsNotInCategories().length === 0) ||
              !restrictions.includes('integrationCreateCategory')
            }
          >Add category</Button>
        </Grid>
      </Grid>
      {/* Closing dialog */}
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure you want to delete?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Category will be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button size="large" onClick={() => { setOpenDialog(false) }} color="primary">No</Button>
          <Button size="large" onClick={deleteCat()} color="primary">Yes</Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

export default React.memo(CategoriesStep)
