import { whiteColor } from "assets/jss/material-dashboard-react";
import { primaryColour } from "assets/jss/material-dashboard-react";
import { grayColor } from "assets/jss/material-dashboard-react";

const transactionStyle = theme => ({
  pWrapper: {
    minHeight: "100vh",
    padding: theme.spacing(2),
  },
  pWrapperPricing: {
    minHeight: "100vh",
    padding: theme.spacing(2),
    [theme.breakpoints.between(351, 900)]: {
      overflowX: 'hidden'
    },
  },
  pContainer: {
    backgroundColor: whiteColor,
    borderRadius: 30,
    position: "relative",
    paddingBottom: 46,
  },
  cFormContaner: {
    margin: "0 auto",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    maxWidth: '100%',
    [theme.breakpoints.down("xs")]: {
      maxWidth: 400,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    }
  },
  cFormContanerNoLimits: {
    margin: "0 auto",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    maxWidth: '100%',
    //overflowX: 'hidden'
  },
  cWidthLimiter: {
    maxWidth: 535,
    [theme.breakpoints.up("xl")]: {
      maxWidth: 650,
    }
  },
  headerOneLine: {
    textAlign: "center",
    lineHeight: "100%",
    [theme.breakpoints.up("sm")]: {
      lineHeight: "130%",
    },
    [theme.breakpoints.down("xs")]: {
      fontWeight: 500
    },
    "&::after": {
      content: `" "`,
      display: "block",
      width: 30,
      height: 2,
      backgroundColor: primaryColour[500],
      margin: "12px auto 0 auto",
      [theme.breakpoints.down("xs")]: {
        content: 'none',
      }

    }
  },
  headerTwoLine: {
    lineHeight: 1.2,
    "&::after": {
      content: `" "`,
      display: "block",
      width: "100%",
      height: 1,
      backgroundColor: primaryColour[50],
      margin: "12px auto 0 auto"
    }
  },
  standardForm: {
    margin: "40px 0",
    lineHeight: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  validationCodeInput: {
    "& .MuiInputBase-input": {
      fontSize: "2.5rem",
      textAlign: "center",
      maxWidth: 50,
      [theme.breakpoints.down("xs")]: {
        fontSize: "1.5rem",
        maxWidth: 40,
      },
    },
  },
  disabled: {
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  enabled: {
    cursor: 'pointer',
  },
  cursorNotAllwed: {
    cursor: 'not-allowed',
  },
  buttonSpacing: {
    marginTop: 10,
    marginBottom: 10
  },
  mainLogo: {
    margin: 30
  },
  smallMainLogo: {
    margin: 5
  },
  subTitleTwo: {
    color: primaryColour[900],
    lineHeight: "130%",
    marginTop: 30,
    marginBottom: 30,
    fontSize: '.875rem',
    [theme.breakpoints.down("xs")]: {
      marginTop: 24,
      marginBottom: 26
    }
  },
  dataPanel: {
    borderRadius: 15,
    padding: 25,
    "& ul li": {
      padding: 0,
    },
    "& ul li > div": {
      margin: 0,
      lineHeight: 1,
      color: grayColor[7]
    },
  },
  adminTHeader: {
    padding: 20,
    minHeight: 88
  },
  textMarginBottom: {
    marginBottom: 20
  },
  ccSubText: {
    textAlign: 'center'
  },
  rioCalculatorWrap: {
    marginRight: 'auto',
    marginLeft: 'auto',
    maxWidth: 1200,
    width: '100%',
    marginBottom: 25,
    [theme.breakpoints.up("sm")]: {
      width: 'calc(100vw - 60px)',
      marginBottom: 53,
    }
  },
  rioCalculatorLeftBlock: {
    paddingTop: 36,
    paddingBottom: 42,
    paddingLeft: 20,
    paddingRight: 20,
    borderBottomLeftRadius: 0,
    borderTopRightRadius: 30,
    borderTopLeftRadius: 30,
    [theme.breakpoints.up("sm")]: {
      maxWidth: '50%',
      borderBottomLeftRadius: 30,
      borderTopRightRadius: 0,
      backgroundColor: '#EAF3F5',
    }
  },
  rioCalculatorRightBlock: {
    backgroundColor: '#00316D',
    borderBottomRightRadius: 30,
    paddingTop: 36,
    paddingBottom: 42,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 30,
    [theme.breakpoints.up("sm")]: {
      maxWidth: '50%',
      borderTopRightRadius: 30,
      borderBottomLeftRadius: 0,
    }
  },
  rioFormBlock: {
    maxWidth: 320,
    margin: 'auto'
  },
  rioFormBlockLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rioTypography4: {
    marginBottom: 32,
    textAlign: 'center'
  },
  rioTypographyFormItem: {
    marginBottom: 14,
    marginTop: 10
  },
  rioCalculationResult: {
    fontSize: 24,
    color: primaryColour[800],
    marginTop: 17,
    marginBottom: 24,
    display: 'inline-block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingLeft: 20,
    position: 'relative',
    fontWeight: 900
  },
  roiPercentSign: {
    paddingRight: 23,
    '&:after': {
      content: "'%'",
      position: 'absolute',
      right: 0,
      top: 0
    }
  },
  rioLighterVersion: {
    color: '#24DBC9'
  },
  rioExplanations: {
    fontSize: 14,
    color: whiteColor
  },
  rioWhiteColor: {
    color: whiteColor
  },
  whiteTextField: {
    '& .MuiTypography-colorTextSecondary': {
      color: '#24DBC9',
      zIndex: 1
    },
    '& .MuiInputBase-input': {
      color: '#24DBC9',
      zIndex: 1
    },
    '& .MuiOutlinedInput-notchedOutline': {
      backgroundColor: whiteColor
    }
  },
  rioTextInput: {
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 4
    }
  }
});

export default transactionStyle;
