import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { allowedServices } from '../../SelectService';
import { Services } from "helpers/types";

export default ({
  selectedService,
  onChange,
}: SelectServiceProps) => {
  
  return (
    <>
      <FormControl fullWidth style={{maxWidth: 375}}>
        <InputLabel htmlFor="p-integration-language">What Service do you provide?</InputLabel>
        <Select
          id="p-service"
          value={selectedService}
          variant="outlined"
          label="What Service do you provide?"
          onChange={(e) => {
            onChange(e.target.value as Services);
          }}
        >
          {
            allowedServices.map((service: AllowedService, key: number) => (
            <MenuItem key={key} value={service.value}>{service.label}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </>
  );
}

interface SelectServiceProps {
  selectedService: Services;
  onChange: (service: Services) => void;
}


export interface AllowedService {
  value: Services,
  label: string;
  img?: any;
}
