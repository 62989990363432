import React, {useContext, useMemo, useEffect} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Typography, Paper, List, ListItem, ListItemText, ListItemIcon, Button, Grid } from "@material-ui/core"
import CheckCircleRounded from '@material-ui/icons/CheckCircleRounded'

// core components
import classNames from "classnames"

// Other components
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle"
import { Link } from "react-router-dom"
import usePrice from "../../../hooks/usePrice";
import { round } from '../../../helpers/common';
//import SelectService from "./components/SelectService"
import Counter from "views/common/Counter"
import { useState } from "react"

const useStyles = makeStyles(styles)

const CustomPricingPanel = (props) => {
  const classes = useStyles();
  let state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch);
  const [serviceNumber, setServiceNumber] = useState(state.serviceNumber);
  const [defaultServiceNumber] = useState(state.serviceNumber);
  const [defaultService] = useState(state.service);
  const {
    index,
    thePlan,
    theFeatureList,
    totalAmount,
    theFeatureListPricing,
    popular,
    showSign,
    service,
    icon,
    thePlanLabel,
    classes: customClasses,
    buttonLabel,
    theme,
    showTotalLabel,
  } = props;

  const finalPrice = usePrice({
    plan: props, 
    currency: state.currency, 
    type: state.cmPlanPayRec,
    number: serviceNumber,
  });

  const isHighlightedPanel = () => {
    if (!state.isLoggedIn) {
      if (thePlan === state.recommendedPlan) {
        return true
      }
    }
    return false
  }

  const finalPricePretty = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = showSign ? currencyDetails.sign : '';
    return `${sign}${round(finalPrice)}`
  }, [
    state.currency, 
    state.cmPlanPayRec, 
    serviceNumber,
    props,
    service,
  ]);

  const slittedPrice = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = showSign ? currencyDetails.sign : '';
    if (typeof(finalPrice) === 'number') {
      const pr = finalPrice / serviceNumber;
      const value = state.cmPlanPayRec ? ( pr / 12 ) : finalPrice / serviceNumber;
      return `${sign}${round(Number.isNaN(value) ? '0' : value)}/${props.unitLabel}/${state.cmPlanPayRec ? 'Month^' : 'Month'}`
    }
    return '';
  }, [
    state.currency,
    state.cmPlanPayRec,
    serviceNumber,
    props.unitLabel,
    service,
  ]);

  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { 
      serviceNumber,
    }
    });
  }, [
    serviceNumber,
  ]);

  const buttonActionAndStyle = () => {
    const url = thePlan?.toLowerCase() === 'contact-us' ? 
      '/contact-us' : 
      state.upDownGradePlanUrl + "?plan=" + thePlan.toLowerCase() + '&' + service + '=' + serviceNumber;

    const currentPlan = state?.originPricingPlan;
    const sid = props?.[`sid${state.currency}`] || props.sid;
    if (!sid.length || thePlan?.toLowerCase() === 'contact-us') {
     return (
       <Button
         component={Link}
         to={'/contact-us/'}
         variant="contained"
         color="primary"
         size="small"
         fullWidth
         className={classes.submitButton}
       >
         { buttonLabel || 'GET IN TOUCH' }
       </Button>
     )
    }

    if (state.isLoggedIn) {
      if (thePlan === 'Essential') {
        return null;
      }
      if (
        thePlan === state.currentPlan &&
        currentPlan === sid?.[(state.cmPlanPayRec ? 0 : 1)] && 
        !!state?.signedInUser?.data?.user?.stripeAccount?.subscriptionId &&
        defaultServiceNumber === serviceNumber &&
        defaultService === service
      ) {
        return (
          <Button
            component={Link}
            to={state.cancelPlanUrl + "?plan=" + thePlan.toLowerCase()}
            variant="outlined"
            color="default"
            size="small"
            fullWidth
            disabled={!state?.signedInUser?.data?.user?.stripeAccount?.subscriptionId}
            className={classNames(classes.submitButton, classes.cancelBtn)}
          >
            Cancel subscription
          </Button>
        )
      } else {
        return (
          <Button
            component={Link}
            to={url}
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            className={classNames('submitPlanButton', classes.submitButton)}
            data-cy={'button-get-plan'}
          >
            Get {thePlanLabel || thePlan}
          </Button>
        )
      }
    }

    return (
     <Button
       component={Link}
       to={state.signUpPlanUrl + "?plan=" + thePlan.toLowerCase() + '&' + service + '=' + serviceNumber}
       variant="contained"
       color={popular ? 'default' : 'primary'}
       size="small"
       fullWidth
       className={classNames(classes.submitButton, {[classes.popularPricingPlan]: popular})}
       data-cy={'button-get-plan'}
     >
       {`START ${buttonLabel || thePlanLabel}`}
     </Button>
    )
  }

  return (
    <Paper
      key={index}
      elevation={2}
      style={{ backgroundColor: theme }}
      className={classNames(
        classes.pricingPaper,
        'pricing-plan-card',
        customClasses,
        {
          [classes.pricingRecommended]: isHighlightedPanel(),
          [classes.firstOnePricingPaper]: !index,
          [classes.lastOnePricingPaper]: index === totalAmount - 1,
          [classes.popularOne]: popular
        }
      )}
    >
      <img
        className={classes.planIcon}
        src={icon}
        alt={thePlan}
      />
      <Typography variant="overline" className={classNames('plan-name', classes.planName)}>{thePlanLabel || thePlan}</Typography>
      { slittedPrice && 
        <p className="plan-price splitted-price" data-cy={'price'}>{slittedPrice}</p>
      }
      <Typography className={classNames(classes.priceFont, {
        [classes.priceOffset]: !slittedPrice
      })} variant="h2">
        <span className="plan-price" data-cy={'price'}>
          { showTotalLabel && 'Total: ' } 
          {finalPricePretty}
        </span>
        <span style={{
          fontSize: 14, 
        }} data-cy={'cm-plan-pay-rec'}>
          {
            showSign ?
            !state.cmPlanPayRec ? '/ Month' : '/ Year'
            : null 
          }
        </span>
      </Typography>
        {
          theFeatureListPricing.map((item, key) => {
            return (
              <p key={key} className={classes.mainFeature} dangerouslySetInnerHTML={{__html: item}} />
            )
          })
        }
        {
          props.showCounter && (
            <div style={{
              margin: '16px 0'
            }}>
              <Counter 
                number={serviceNumber}
                setNumber={(val) => setServiceNumber(val)}
              />
            </div>
          )
        }
      <List dense className={classNames(classes.priceList, 'features-list', {[classes.priceListNoLine]: finalPricePretty === 'LET’S TALK'})}>
        {theFeatureList.map((prop, key) => {
          return (
            <ListItem key={key} disableGutters>
              <ListItemIcon className="feature-icon">
                <CheckCircleRounded fontSize="small" className={classes.checkMarkIcon} />
              </ListItemIcon>
              <ListItemText
                className={classes.featureListItemText}
                variant="caption"
                id={`price-list-${key}`}
              >
                <span dangerouslySetInnerHTML={{__html: prop}} />
              </ListItemText>
            </ListItem>
          )
        })}
      </List>
      <div style={{marginTop: 'auto'}} className="pricing-submit-button">
        {buttonActionAndStyle()}
      </div>
    </Paper>

  );
}

export default CustomPricingPanel
