import React, {Fragment, useEffect, useState, useContext} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"

// Core components

// Other components
import CustomPricingPanelsSlider from "components/CustomPricing/CustomPricingPanelsSlider"
import AdminTransactionalHeader from "../../components/AdminTransactionalHeader/AdminTransactionalHeader"
import { useGlobalState } from "hooks/useGlobalState";

// Style
import styles from "assets/jss/material-dashboard-react/layouts/transactionalStyle"
import SelectService from "components/CustomPricing/SelectService"

const useStyles = makeStyles(styles)

const Pricing = props => {
  const classes = useStyles();
  const [service, setService] = useState('self-storage');
  const dispatchState = useContext(useGlobalState.Dispatch);
  const updateService = (service) => {
    setService(service);

    if (props.location.pathname !== "/facilities") {
      props.history.push("/pricing/facilities?service="+service);
    }
  }

  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { 
        service
      }
    });
  }, [
    service,
  ]);

  return (
    <Fragment>
      <AdminTransactionalHeader
        homeBtn={() => { props.history.push("/") }}
        backBtn={false}
        logo={true}
        closeBtn={false}
      />
      <Grid container spacing={0} justify="center" className={classes.cFormContanerNoLimits}>
        <Grid item xs={12} sm={7} md={5}>
          <Typography variant="h1" className={classes.headerOneLine} data-cy={'main-header'}>Select the option that best describes your company</Typography>
          <br/>
          <br/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{
          maxWidth: 1336,
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%'
        }}
        >
         {props.location.pathname === "/pricing" &&
            <SelectService 
              selectedService={service}
              setService={updateService}
            />
          }
          {props.location.pathname === "/pricing/facilities" &&
            <CustomPricingPanelsSlider
              selectedService={service}
              setSelectedService={updateService}
            />
          }
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default Pricing
