import React, {useContext, useEffect, createRef, useState, useMemo} from "react";
import axios from 'axios';
import { useHistory } from 'react-router-dom';

// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Button, Typography, Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import { Switch, Redirect } from "react-router-dom";
import { useGlobalState } from "hooks/useGlobalState";

// other components
import Navbar from "components/Navbars/Navbar";
import AdminSidebar from "components/AdminSidebar/AdminSidebar";
import routes from "routes";
import { isMobile } from 'react-device-detect';
import PerfectScrollbar from "perfect-scrollbar";

// styles
import adminStyle from "assets/jss/material-dashboard-react/layouts/adminStyle";
import logo from "assets/img/calcumate-logo@2x.png";
import {mapPlansLimitations, defaultReportConfig} from "../variables/InitState";

let ps;
const useStyles = makeStyles(adminStyle);
const Admin = props => {
  let history = useHistory();
  const classes = useStyles();
  const mainPanel = createRef(); // ref to help us initialize PerfectScrollbar on windows devices
  let state = useContext(useGlobalState.State)
  const dispatchState = useContext(useGlobalState.Dispatch)
  const [openDialog, setOpenDialog] = useState(true); //local state
  const { children } = props;

  useEffect(() => {
    document.querySelector('#root').classList.add('admin-layout');
    return () => document.querySelector('#root').classList.remove('admin-layout');
  }, []);

  const restrictions = useMemo(() => mapPlansLimitations?.[state.currentPlan] || [], [state.currentPlan]);

  const handleDrawerToggle = () => {
    dispatchState({ type: 'root-state', payload: { adminNavigationMobile: !state.adminNavigationMobile } })
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      dispatchState({ type: 'root-state', payload: { adminNavigationMobile: false } })
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  /* eslint-disable */
  useEffect(() => {
    // fetch user data and set in the state if it not set already
    function fetchData() {
      let currency = 'USD';
      if (Object.keys(state.signedInUser.data).length === 0 && state.signedInUser.token) {
        dispatchState({ type: 'root-state', payload: { fetching: true } });
        const customerEmail = (window.location?.search || '')?.split("=")?.[1];  //get customer email from GET params
        axios.post(
          process.env.REACT_APP_API_URL + 'user/get',
          { 
            "cukey": state.signedInUser.cukey, 
            customerEmail,
          },
          { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + state.signedInUser.token } }
        ).then(response => {
          if (response && response.data && response.status === 200) {
            // this is an existing user, set the user details in the state
            // loop through the subscriptions and get the relevant one
            const allPlans = [...state.calcumateCustomPlans, ...state.calcumatePlans, ...state.calcumatePlansOld];
            let selectedPlan = allPlans.filter(plan => {
              if (response.data.user.isCustomPricingPlan && plan.thePlan === "Custom") {
                return true
              }
              if (plan.sidAUD && plan.sidAUD.includes(response.data.user.lastChosenSubscription)) {
                currency = 'AUD';
                return true;
              }
              if (plan.sidGBP && plan.sidGBP.includes(response.data.user.lastChosenSubscription)) {
                currency = 'GBP';
                return true;
              }
              if (plan.sidEUR && plan.sidEUR.includes(response.data.user.lastChosenSubscription)) {
                currency = 'EUR';
                return true;
              }
              return plan.sidUSD.includes(response.data.user.lastChosenSubscription);
            });
            let selectedPlanOrigin = allPlans.filter(plan => {
              if (response.data.user.isCustomPricingPlan && plan.thePlan === "Custom") {
                return true
              }
              if (plan.sidAUD && plan.sidAUD.includes(response.data.user.lastChosenSubscription)) {
                currency = 'AUD';
                return true;
              }
              if (plan.sidGBP && plan.sidGBP.includes(response.data.user.lastChosenSubscription)) {
                currency = 'GBP';
                return true;
              }
              if (plan.sidEUR && plan.sidEUR.includes(response.data.user.lastChosenSubscription)) {
                currency = 'EUR';
                return true;
              }
              return plan.sidUSD.includes(response.data.user.lastChosenSubscription);
            });

            const thePrices = selectedPlan?.[0]?.[currency];

            const sids = selectedPlan?.[0]?.[`sid${currency}`];
            let currentPlanLabel = 'cancelled';
            if (selectedPlan?.[0] && selectedPlan[0].thePlanLabel) {
              currentPlanLabel = selectedPlan[0].thePlanLabel;
            } else if (selectedPlan?.[0]) {
              currentPlanLabel = selectedPlan[0].thePlan;
            }

            const currentPlan = selectedPlan[0] ? selectedPlan[0].thePlan : 'cancelled';
            dispatchState({
              type: 'root-state',
              payload: {
                currentPlan,
                currentPlanLabel,
                serviceNumber: response.data.user.serviceNumber,
                originServiceNumber: response.data.user.serviceNumber,
                service: response.data.user.service,
                adminMode: {
                  cuid: response.data.user.cuid,
                  cuemail: response.data.user.customerEmail,
                },
                locations: response.data.user.locations,
                originCurrentPlan: selectedPlanOrigin?.[0]?.thePlan ? selectedPlanOrigin?.[0]?.thePlan : currentPlan,
                currentPlanPrice: selectedPlan[0] ? thePrices[sids.indexOf(response.data.user.lastChosenSubscription)] : 0,
                currentPlanPriceRec: sids?.indexOf(response.data.user.lastChosenSubscription) === 1 ? "/mo" : "/yr",
                originPlanPriceRec: sids?.indexOf(response.data.user.lastChosenSubscription) === 1 ? "/mo" : "/yr",
                originPricingPlan: response.data.user.lastChosenSubscription,
                originCurrency: currency,
                showInactiveMessage: !response.data.user.active,
                fetching: false,
                highlightedPlan: state.calcumatePlans.findIndex(plan => plan.thePlan === selectedPlan?.[0]?.thePlan),
                currency,
                signedInUser: {
                  ...state.signedInUser, data: response.data
                },
                reportConfig: response.data.user.reportConfig || defaultReportConfig,
                subscriptionFeatures: state.subscriptionFeatures,
                geoMethod: response.data.user.geoMethod || 'manual'
              }
            });
          } else {
            // redirect to the login page and delete the user details from the state
            dispatchState({ type: 'root-state', payload: { isLoggedIn: false, fetching: false, signedInUser: { token: '', cukey: '', data: {} } } });
            history.replace('/login');
            window.location.reload();
          }
        }).catch(error => {
          console.error(error);
          // redirect to the login page and delete the user details from the state
          dispatchState({ type: 'root-state', payload: { isLoggedIn: false, fetching: false, signedInUser: { token: '', cukey: '', data: {} } } });
          history.replace('/login');
          //window.location.reload();
        })

      }
    }
    fetchData();
    if (navigator.platform.indexOf("Win") > -1 && mainPanel?.current !== null) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1 && ps?.destroy) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };


  }, []);
  /* eslint-enable */

  const activeRoutes = useMemo(() => {
    if (!restrictions.includes('generationReports')) {
      return routes.filter(route => route.name !== "REPORTING");
    }
    return routes;
  }, [state.signedInUser, restrictions]);

  return (
    <div>
      {state.isLoggedIn && state.signedInUser.token !== '' ?
        <div className={classes.wrapper}>
          <AdminSidebar
            routes={activeRoutes}
            logo={logo}
            handleDrawerToggle={handleDrawerToggle}
            open={state.adminNavigationMobile}
          />
          <div className={classes.mainPanel} ref={mainPanel}>
            <Navbar
              routes={activeRoutes}
              handleDrawerToggle={handleDrawerToggle}
            />
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>
                  <Redirect exact from="/admin" to="/admin/my-account" />
                </Switch>
                {children}
              </div>
            </div>
          </div>
          {isMobile ?
            <Dialog
              open={openDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant="h5" component="h2" className={classes.textMarginBottom}>Hello. It appears you are using a mobile device. Please switch to a desktop computer as this portal will not display correctly on a mobile device. This will be updated soon. We apologise for any inconvenience. Thank you.</Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button size="large" onClick={() => { setOpenDialog(false) }} color="primary">OK</Button>
              </DialogActions>
            </Dialog>
            : ''}
        </div>
        : <Switch>
          <Redirect path from="/admin/my-calcumate" to="/login" />
          <Redirect path from="/admin" to="/login" />
        </Switch>
      }
    </div >
  );
}

export default Admin
