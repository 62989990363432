import React, {useContext, useEffect, useMemo, useState} from "react"

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles"
import EditRounded from '@material-ui/icons/EditRounded'
import ClearRounded from '@material-ui/icons/ClearRounded'
import { Typography, Grid, Card, Fab, Radio, List, ListItem, ListItemIcon, ListItemText, ListItemSecondaryAction, Collapse, Hidden } from "@material-ui/core"
import SelectService from 'components/CustomPricing/CustomPricingPanel/components/SelectService';

// core components
import classNames from "classnames"

// Other components
import CustomPricingSwitch from "./CustomPricingPanel/CustomPricingSwitch"
import { useGlobalState } from "hooks/useGlobalState"

// Style
import styles from "assets/jss/material-dashboard-react/components/CustomPricingStyle";
import usePrice from "../../hooks/usePrice";
import { round } from '../../helpers/common';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Currencies from "./Currencies";
import CloneDeep from 'lodash.clonedeep';
import {saveIntegration} from "../../helpers/SaveIntegration";
import Counter from "views/common/Counter"

const useStyles = makeStyles(styles);

const selectedPricingPlan = (state) => {
  const allPlans = [...state.calcumateCustomPlans, ...state.calcumatePlans, ...state.calcumatePlansOld];
  return allPlans.find(plan => {
    if (state.signedInUser?.data?.user?.isCustomPricingPlan && plan.thePlan === "Custom") {
      return true
    }
    if (plan.sidAUD && plan.sidAUD.includes(state.originPricingPlan)) {
      return true;
    }
    if (plan.sidGBP && plan.sidGBP.includes(state.originPricingPlan)) {
      return true;
    }
    if (plan.sidEUR && plan.sidEUR.includes(state.originPricingPlan)) {
      return true;
    }
    return plan.sidUSD.includes(state.originPricingPlan);
  });
}

const currentPricingPlan = (state) => {
  const allPlans = [...state.calcumateCustomPlans, ...state.calcumatePlans, ...state.calcumatePlansOld];
  return allPlans.find(plan => {
    if (state.signedInUser?.data?.user?.isCustomPricingPlan && plan.thePlan === "Custom") {
      return true
    }
    if (plan.sidAUD && plan.sidAUD.includes(state.signedInUser?.data?.user?.lastChosenSubscription)) {
      return true;
    }
    if (plan.sidGBP && plan.sidGBP.includes(state.signedInUser?.data?.user?.lastChosenSubscription)) {
      return true;
    }
    if (plan.sidEUR && plan.sidEUR.includes(state.signedInUser?.data?.user?.lastChosenSubscription)) {
      return true;
    }
    return plan.sidUSD.includes(state.signedInUser?.data?.user?.lastChosenSubscription);
  });
}

//currentPlan



const Price = ({
  prop,
  serviceNumber,
  setServiceNumber,
  service,
}) => {
  const classes = useStyles()
  const state = useContext(useGlobalState.State);
  const currencySign = useMemo(()=>{
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = prop.showSign ? currencyDetails.sign : '';
    return sign;
  }, [
    state.availableCurrencies,
    prop.showSign,
    state.currency,
  ]);

  const price = usePrice({
    plan: prop,
    currency: state.currency,
    type: state.cmPlanPayRec,
    number: serviceNumber,
  }); 

  const slittedPrice = useMemo(() => {
    if (typeof(price) === 'number') {
      const value = price / serviceNumber;
      return `${currencySign}${round(Number.isNaN(value) ? '0' : value)}/${prop.unitLabel}/${state.cmPlanPayRec ? 'Year' : 'Month'}`
    }
    return '';
  }, [
    state.currency, 
    state.cmPlanPayRec, 
    serviceNumber,
    prop.unitLabel,
    service,
  ]);

  return (
    <Typography className={classNames(classes.priceFont, 'price')} variant="h2">
      {
        prop.showCounter && (
          <Grid style={{marginRight: 7, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <Counter
              number={serviceNumber}
              setNumber={(val) => setServiceNumber(val)}
              hideLabel={true}
            />
            <span style={{fontSize: 10}}>Number of facilities</span>
          </Grid>
        )
      }
      <div className={classNames({[classes.widgetPrice]:slittedPrice})}>
        <p style={{minWidth: 150}}>
          {slittedPrice}
        </p>
        <p className="total-price">
        {
          currencySign + round(price)
        }
        {
          prop.showSign ? (
            <Typography variant="subtitle1" component="p" display="inline">
              {state.cmPlanPayRec ? "/yr" : "/mo"}
            </Typography>
          ) : null
        }
        </p>
      </div>
    </Typography>
  )
}

const Plan = ({
  prop,
  serviceNumber,
  setServiceNumber,
  index: key,
  save,
  setUpdating,
  service,
}) => {
  const classes = useStyles()
  const state = useContext(useGlobalState.State);
  const dispatchState = useContext(useGlobalState.Dispatch);
  if (
    (
      prop.thePlan === 'Essential' && 
      state.signedInUser?.data?.user?.stripeAccount?.subscriptionId
    ) ||
    (
      state.originCurrentPlan && 
      state.originCurrentPlan !== 'Essential' && 
      prop.thePlan === 'Essential'
    ) ||
    (
      !state.originCurrentPlan && 
      state.calcumatePlans[state.highlightedPlan]?.thePlan !== 'Essential' && 
      prop.thePlan === 'Essential'
    )
  ) return null;

  const labelId = `checkbox-list-label-${key}`;

  return (
    <ListItem
      key={key}
      role={undefined}
      button
      className={classNames(classes.planListItem, classes.planSelected, prop.classes)}
      onClick={async () => {
        if (state.currentPlan === 'Generator' && prop.thePlan === 'Core') {
          setShowAlert(true);
        }
        const sids = prop[`sid${state.currency}`];
        const price = usePrice({
          plan: prop,
          currency: state.currency, 
          type: state.currentPlanPriceRec.indexOf('yr') > -1,
          number: serviceNumber,
        });       

        const signedInUser = {
        ...state.signedInUser,
            data: {
          ...state.signedInUser.data,
              user: {
            ...state.signedInUser.data.user,
              lastChosenSubscription: sids[state.cmPlanPayRec ? 0 : 1],
              serviceNumber,
              service,
            },
          }
        }
        const newState = {
          signedInUser,
          highlightedPlan: key,
          currentPlan: prop.thePlan,
          currentPlanPrice: price,
          serviceNumber,
          service,
        }
        dispatchState({
          type: 'root-state',
          payload: newState
        })
        if (save && setUpdating) {
          setUpdating(true)
          const newState = CloneDeep({
            ...state,
            signedInUser
          })

          try {
            await saveIntegration(newState, false, dispatchState);
          } catch (e) {
            console.log('Saving error:', e)
          }
          setUpdating(false)
        }
      }}
    >
      <ListItemIcon>
        <Radio
          edge="start"
          checked={prop.thePlan === state.currentPlan}
          color="primary"
          name="plan"
          tabIndex={-1}
          value={prop.thePlan}
        />
      </ListItemIcon>
      <ListItemText id={labelId}>
        <Typography variant="overline" className={classes.CustomPricingOverline}>{prop.thePlanLabel || prop.thePlan}</Typography>
        <Hidden xsDown><Typography variant="subtitle2" component="p">{(state.cmPlanPayRec ? prop.theDiscount.yr : prop.theDiscount.mo)}</Typography></Hidden>
      </ListItemText>
      <ListItemSecondaryAction className={prop.classes}>
        <Price 
          prop={prop} 
          serviceNumber={serviceNumber} 
          setServiceNumber={setServiceNumber}
          service={service}
        />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const PriceSelectedPlan = () => {
  const state = useContext(useGlobalState.State);
  let highlightedPlan = state.calcumatePlans[state.highlightedPlan];
  if (!highlightedPlan) { //TODO move into helper
    highlightedPlan = selectedPricingPlan(state);
  }

  const planPrice = usePrice({
    plan: highlightedPlan, 
    currency: state.currency, 
    type: state.cmPlanPayRec,
    number: state.serviceNumber,
  });

  const price = useMemo(() => {
    const currencyDetails = state.availableCurrencies.find(currency => currency.currency === state.currency);
    const sign = highlightedPlan?.showSign ? currencyDetails.sign : '';
    if (!planPrice) return false
    return `${sign}${planPrice}`
  }, [
    state.cmPlanPayRec,
    state.highlightedPlan,
    state.currency,
    state.serviceNumber,
    state.service,
  ]);

  if (!price) {
    return <strong>Canceled</strong>
  }
  const period = `${state.cmPlanPayRec ? "/yr" : "/mo"}. `;
  return (
    <strong>{price} {highlightedPlan?.showSign ? period : null}</strong>
  )
}


const CustomPricingWidget = ({save = false, setUpdating}) => {
  const classes = useStyles()
  const dispatchState = useContext(useGlobalState.Dispatch);
  const state = useContext(useGlobalState.State);
  const [showAlert, setShowAlert] = useState(false);
  const [serviceNumber, setServiceNumber] = useState(state.serviceNumber);
  const [service, setService] = useState(state.service);
  
  useEffect(() => {
    dispatchState({ type: 'root-state', payload: { 
      serviceNumber: serviceNumber, 
      service,
      highlightedPlan: state.calcumatePlans.findIndex(plan => plan.thePlan === state.currentPlan),
    } 
  });
  }, [
    serviceNumber,
    service,
    state.currentPlan,
  ]);

  const pricingPlan = useMemo(() => {
    if (state.signedInUser?.data?.user?.lastChosenSubscription) {
      const plan = currentPricingPlan(state);
      return plan?.thePlanLabel || plan?.thePlan;
    } else if (state.calcumatePlans?.[state.highlightedPlan]?.thePlan) {
      return state.calcumatePlans[state.highlightedPlan]?.thePlan;
    }
    const plan = selectedPricingPlan(state);
    return plan?.thePlanLabel || plan?.thePlan;
  }, [
    state.highlightedPlan,
    state.calcumatePlans,
    serviceNumber,
    state.currency,
    state.cmPlanPayRec,
    state.serviceNumber,
    state.service,
    state.currentPlan
  ]);

  const allPlans = useMemo(() => {
    if (!['container-storage', 'self-storage', 'kiosks', 'portable-storage', 'movers'].includes(service)) {
      return state.calcumatePlans.filter(plan => ['Contact-us'].includes(plan.thePlan));
    }
    return state.calcumatePlans.filter(plan => {
      if (plan.service) {
        return plan.service === service;
      }
      return true;
    });
  }, [
    service,
    state.currency,
    state.currentPlanPriceRec,
    state.service,
  ]);

  return (
    <Card variant="outlined" className={classes.CustomPricingW}>
      <Grid container spacing={0} direction="row" justify="space-between" alignItems="center" className={classes.planSelected}>
        <Grid>
          <Typography
            variant="overline"
            className={classes.CustomPricingOverline}>
            {pricingPlan}
          </Typography>
          <Typography
            variant="subtitle2" component="p">
            <PriceSelectedPlan />
            {
              state.calcumatePlans[state.highlightedPlan] && (
                <Hidden xsDown>{state.cmPlanPayRec ?
                      state.calcumatePlans[state.highlightedPlan].theDiscount.yr :
                      state.calcumatePlans[state.highlightedPlan].theDiscount.mo
                  }
                </Hidden>
              )
            }
          </Typography>
        </Grid>
        <Grid>
          <Fab
            color="primary"
            aria-label="Change plan"
            size="medium"
            onClick={() => dispatchState({ type: 'root-state', payload: { cmPricingWidgetVisible: (state.cmPricingWidgetVisible ? false : true) } })}
          >
            {state.cmPricingWidgetVisible ? (
              <ClearRounded />
            ) : (
                <EditRounded />
              )}
          </Fab>
        </Grid>
      </Grid>
      <Collapse in={state.cmPricingWidgetVisible}>
        <Grid container className={classes.planList}>
          <Grid item xs={12} sm={12} md={12} className={classes.switchPadding}>
            <Grid className={classes.pricingModeWrap}>
              <div className={classes.planSwitcherDesktop}>
                <CustomPricingSwitch />
                <Currencies />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} style={{display: 'flex', justifyContent: 'center', marginBottom: 30}} className={classes.switchPadding}>
            <SelectService
              selectedService={service}
              onChange={setService}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <List className={classes.planListPad}>
              {allPlans
                .map((prop, key) => (
                  <Plan 
                    prop={prop} 
                    key={key}
                    index={key}
                    serviceNumber={serviceNumber} 
                    setServiceNumber={setServiceNumber}
                    save={save}
                    setUpdating={setUpdating}
                    service={service}
                  />
                ))}
            </List>
          </Grid>
        </Grid>
      </Collapse>
      <Snackbar
        open={showAlert}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
          zIndex: 10
        }}
        onClose={() => setShowAlert(false)}
      >
        <Alert onClose={() => setShowAlert(false)} severity={'info'}>
          Some Calcumate features are only available for our Generator plan. Switching to Core will remove these options from your calculator.
        </Alert>
      </Snackbar>
    </Card>
  )
}

export default CustomPricingWidget
